import { useState, useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { Button } from "react-bootstrap";
import OutsideClickHandler from "react-outside-click-handler";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import CustomSelect from "../../../Common/CustomSelect/CustomSelect";
import capitalizeFirstLetter from "../../../../utils/capitalizeFirstLetter";
import Form from "react-bootstrap/Form";
import API from "../../../../api";
import {
  POST_JOB_TITLE,
  EMP_LIST,
  JOB_POST_DECISION,
  JOB_POST_FIELDS_AI,
  numericWithCommaAndDecimal,
  scriptOnlyHTMLTagRegex,
  jobTitleRegex,
  JOB_GEN_METHOD,
} from "../../../../constants";
import {
  changeJobDecisionToCopy,
  changeJobDecisionToSyetemGen,
  changeJobDecisionToZoe,
  clearJobDecision,
  openJobDecisionModal,
  setProgressModel,
} from "../../../../redux/slices/JobDecisionSlice";
import { getCitiesAsync } from "../../../../redux/slices/AuthSlice";
import RecommendedSection from "./RecommendedSection";
import {
  closeDivModal,
  openDivModal,
  openZoeModal,
} from "../../../../redux/slices/JobPostZoeModalSlice";
import ContentModal from "../../../Common/ContentModal/ContentModal";

const modules = {
  toolbar: [
    ["bold", "italic", "underline"],
    [
      // { list: "unordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" },
    ],
  ],
  clipboard: {
    // toggle to add extra line breaks when pasting HTML:
    matchVisual: false,
  },
};
const formatsP = [""];
const formats = ["list"];
const allFormats = ["bold", "italic", "underline", "list", "bullet", "indent"];

// Define a handler for the custom formats to make them readonly
const customFormats = {
  header: { readonly: true },
  list: { readonly: true },
  link: { readonly: true },
};

const PostJobForm = ({
  loader,
  handleYesButtonPostNow,
  postAJobHandler,
  setHandleNoButtonPostNow,
  handleYesButtonClick,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const headRef = useRef(null);

  const fieldRefs = {
    jobTitle: useRef(null),
    jobPosition: useRef(null),
    promptInput: useRef(null),
    JobDes: useRef(null),
    rules: useRef(null),
    skill: useRef(null),
    mustSkill: useRef(null),
    niceToHave: useRef(null),
    benefits: useRef(null),
    workingHours: useRef(null),
    skillTags: useRef(null),
    currency: useRef(null),
    salaryStart: useRef(null),
    salaryEnd: useRef(null),
    salaryRange: useRef(null),
    jobType: useRef(null),
    state: useRef(null),
    city: useRef(null),
  };

  const location = useLocation();

  const { user, mode, postJobId, userCountry } = useSelector(
    (state) => state.auth
  );
  const { jobDecision, isJobDecisionModalOpen, prevJobDecisionState } =
    useSelector((state) => state.jobDecision);
  const { postJobEditForm } = useSelector((state) => state.employerDashboard);

  const { noClicked, isDivModelOpen } = useSelector(
    (state) => state.jobPostZoe
  );

  const [spinner, setSpinner] = useState(false);
  const [careerOption, setCareerOption] = useState([]);
  const [currencyOption, setCurrencyOption] = useState([]);
  const [salaryRangeOption, setSalaryRangeOption] = useState([]);
  const [jobTypeOption, setJobTypeOption] = useState([]);
  const [reportingToOption, setReportingToOption] = useState([]);
  const [WorkMethOption, setWorkMethOption] = useState([]);
  const [cityOption, setCityOption] = useState([]);
  const [cityMsg, setCityMsg] = useState("");
  const [stateOption, setStateOption] = useState([]);
  const [hiringSteps, setHiringSteps] = useState([]);
  const [checked, setChecked] = useState([]);

  const [selectedJobCountry, setSelectedJobCountry] = useState(null);

  const [allSkillTags, setAllSkillTags] = useState([]);
  const [selectSkillTags, setSelectSkillTags] = useState([]);
  const [allSkillTagsHide, setAllSkillTagsHide] = useState(true);
  const [promptMessage, setPromptMessage] = useState("");
  const [showPromptMessageField, setShowPromptMessageField] = useState(false);

  // text seatch
  const [stateDDHide, setStateDDHide] = useState(true);
  const [stateSearch, setStateSearch] = useState("");

  const [cityDDHide, setCityDDHide] = useState(true);
  const [citySearch, setCitySearch] = useState("");

  const [showFields, setShowFields] = useState(false);
  const [postingJob, setPostingJob] = useState(false);
  const [customStage, setCustomStage] = useState(false);
  const [newStagesValue, setNewStagesValue] = useState("");

  // new values to set in the text editor START
  const [jobDesNewValue, setJobDesNewValue] = useState("");
  const [rulesNewValue, setRulesNewValue] = useState("");
  const [skillNewValue, setSkillNewValue] = useState("");
  const [mustSkillNewValue, setMustSkillNewValue] = useState("");
  const [niceToHaveNewValue, setNiceToHaveNewValue] = useState("");
  const [benefitsNewValue, setBenefitsNewValue] = useState("");
  const [workingHoursNewValue, setWorkingHoursNewValue] = useState("");
  const [interviewProNewValue, setInterviewProNewValue] = useState("");
  const [recNotesNewValue, setRecNotesNewValue] = useState("");
  // new values to set in the text editor END

  /* Zoe thing array start */
  const [recJobDes, setRecJobDes] = useState([]);
  const [recRules, setRecRules] = useState([]);
  const [recSkills, setRecSkills] = useState([]);
  const [recMustSkills, setRecMustSkills] = useState([]);
  const [recNiceToHave, setRecNiceToHave] = useState([]);
  const [recBenefits, setRecBenefits] = useState([]);
  const [recInterviewPro, setRecInterviewPro] = useState([]);
  const [recWorkingHours, setRecWorkingHours] = useState([]);
  const [recRecruiterNotes, setRecRecruiterNotes] = useState([]);
  /* Zoe thing array end */

  /* Prompt states for the field that has text editors start */
  const [jobDesPrompt, setJobDesPrompt] = useState("");
  const [rulesPrompt, setRulesPrompt] = useState("");
  const [skillsPrompt, setSkillsPrompt] = useState("");
  const [mustSkillsPrompt, setMustSkillsPrompt] = useState("");
  const [niceToHavePrompt, setNiceToHavePrompt] = useState("");
  const [benefitsPrompt, setBenefitsPrompt] = useState("");
  const [workingHoursPrompt, setWorkingHoursPrompt] = useState("");
  const [interviewProPrompt, setInterviewProPrompt] = useState("");
  const [recruiterNotesPrompt, setRecruiterNotesPrompt] = useState("");

  /* Prompt states for the field that has text editors end */

  /* constants*/
  const is_Job_Copy_Paste = jobDecision === JOB_POST_DECISION.COPY_PASTE;

  const onRadioButtonChange = (event, field) => {
    const newValue = event.target.value;
    // console.log("newValue =====>", newValue);
    switch (field) {
      case "JobDes":
        setJobDesNewValue(newValue);
        break;
      case "rules":
        setRulesNewValue(newValue);
        break;
      case "skill":
        setSkillNewValue(newValue);
        break;
      case "mustSkill":
        setMustSkillNewValue(newValue);
        break;
      case "niceToHave":
        setNiceToHaveNewValue(newValue);
        break;
      case "benefits":
        setBenefitsNewValue(newValue);
        break;
      case "workingHours":
        setWorkingHoursNewValue(newValue);
        break;
      case "interviewPro":
        setInterviewProNewValue(newValue);
        break;
      case "recNotes":
        setRecNotesNewValue(newValue);
        break;
      default:
        setJobDesNewValue(newValue);
        break;
    }
  };

  const setFieldPrompt = (field, promptText) => {
    switch (field) {
      case "JobDes":
        setJobDesPrompt(promptText);
        break;
      case "rules":
        setRulesPrompt(promptText);
        break;
      case "skill":
        setSkillsPrompt(promptText);
        break;
      case "mustSkill":
        setMustSkillsPrompt(promptText);
        break;
      case "niceToHave":
        setNiceToHavePrompt(promptText);
        break;
      case "benefits":
        setBenefitsPrompt(promptText);
        break;
      case "workingHours":
        setWorkingHoursPrompt(promptText);
        break;
      case "interviewPro":
        setInterviewProPrompt(promptText);
        break;
      case "recNotes":
        setRecruiterNotesPrompt(promptText);
        break;
      default:
        setJobDesPrompt(promptText);
        break;
    }
  };

  const updateBtnHandler = async (field, data) => {
    // console.log( "Data is ======>", data );
    await formik.setFieldValue(field, data);
  };

  const clearRecommendArray = async (field) => {
    switch (field) {
      case "JobDes":
        setRecJobDes([]);
        break;
      case "rules":
        setRecRules([]);
        break;
      case "skill":
        setRecSkills([]);
        break;
      case "mustSkill":
        setRecMustSkills([]);
        break;
      case "niceToHave":
        setRecNiceToHave([]);
        break;
      case "benefits":
        setRecBenefits([]);
        break;
      case "workingHours":
        setRecWorkingHours([]);
        break;
      case "interviewPro":
        setRecInterviewPro([]);
        break;
      case "recNotes":
        setRecRecruiterNotes([]);
        break;

      default:
        setRecJobDes([]);
        break;
    }
  };

  const updateRecommendArray = async (field, value, promptText) => {
    const promptHTML = value;
    // const promptHTML = ({ value });

    /*const newObject = {
                  upper: upper,
                  lower: lower,
                  data: promptHTML,
                  dataToSet: normalPrompt,
                };*/

    switch (field) {
      case "JobDes":
        setRecJobDes((pre) => [
          ...pre,
          {
            upper: 1,
            lower: pre[pre?.length - 1]?.lower
              ? pre[pre?.length - 1]?.lower + 1
              : 1,
            data: promptHTML,
            // dataToSet: normalPrompt,
            promptText: promptText,
          },
        ]);
        break;
      case "rules":
        setRecRules((pre) => [
          ...pre,
          {
            upper: 1,
            lower: pre[pre?.length - 1]?.lower
              ? pre[pre?.length - 1]?.lower + 1
              : 1,
            data: promptHTML,
            // dataToSet: normalPrompt,
            promptText: promptText,
          },
        ]);
        break;
      case "skill":
        setRecSkills((pre) => [
          ...pre,
          {
            upper: 1,
            lower: pre[pre?.length - 1]?.lower
              ? pre[pre?.length - 1]?.lower + 1
              : 1,
            data: promptHTML,
            // dataToSet: normalPrompt,
            promptText: promptText,
          },
        ]);
        break;
      case "mustSkill":
        setRecMustSkills((pre) => [
          ...pre,
          {
            upper: 1,
            lower: pre[pre?.length - 1]?.lower
              ? pre[pre?.length - 1]?.lower + 1
              : 1,
            data: promptHTML,
            // dataToSet: normalPrompt,
            promptText: promptText,
          },
        ]);
        break;
      case "niceToHave":
        setRecNiceToHave((pre) => [
          ...pre,
          {
            upper: 1,
            lower: pre[pre?.length - 1]?.lower
              ? pre[pre?.length - 1]?.lower + 1
              : 1,
            data: promptHTML,
            // dataToSet: normalPrompt,
            promptText: promptText,
          },
        ]);
        break;
      case "benefits":
        setRecBenefits((pre) => [
          ...pre,
          {
            upper: 1,
            lower: pre[pre?.length - 1]?.lower
              ? pre[pre?.length - 1]?.lower + 1
              : 1,
            data: promptHTML,
            // dataToSet: normalPrompt,
            promptText: promptText,
          },
        ]);
        break;
      case "workingHours":
        setRecWorkingHours((pre) => [
          ...pre,
          {
            upper: 1,
            lower: pre[pre?.length - 1]?.lower
              ? pre[pre?.length - 1]?.lower + 1
              : 1,
            data: promptHTML,
            // dataToSet: normalPrompt,
            promptText: promptText,
          },
        ]);
        break;
      case "interviewPro":
        setRecInterviewPro((pre) => [
          ...pre,
          {
            upper: 1,
            lower: pre[pre?.length - 1]?.lower
              ? pre[pre?.length - 1]?.lower + 1
              : 1,
            data: promptHTML,
            // dataToSet: normalPrompt,
            promptText: promptText,
          },
        ]);
        break;
      case "recNotes":
        setRecRecruiterNotes((pre) => [
          ...pre,
          {
            upper: 1,
            lower: pre[pre?.length - 1]?.lower
              ? pre[pre?.length - 1]?.lower + 1
              : 1,
            data: promptHTML,
            // dataToSet: normalPrompt,
            promptText: promptText,
          },
        ]);
        break;

      default:
        setRecJobDes((pre) => [
          ...pre,
          {
            upper: 1,
            lower: pre[pre?.length - 1]?.lower
              ? pre[pre?.length - 1]?.lower + 1
              : 1,
            data: promptHTML,
            // dataToSet: normalPrompt,
            promptText: promptText,
          },
        ]);
        break;
    }
  };

  const getValueToSend = (field) => {
    switch (field) {
      case "JobDes":
        return recJobDes?.length > 0
          ? recJobDes[recJobDes?.length - 1].data
          : formik?.values?.JobDes;
      case "rules":
        return recRules?.length > 0
          ? recRules[recRules?.length - 1].data
          : formik?.values?.rules;
      case "skill":
        return recSkills?.length > 0
          ? recSkills[recSkills?.length - 1].data
          : formik?.values?.skill;
      case "mustSkill":
        return recMustSkills?.length > 0
          ? recMustSkills[recMustSkills?.length - 1].data
          : formik?.values?.mustSkill;
      case "niceToHave":
        return recNiceToHave?.length > 0
          ? recNiceToHave[recNiceToHave?.length - 1].data
          : formik?.values?.niceToHave;
      case "benefits":
        return recBenefits?.length > 0
          ? recBenefits[recBenefits?.length - 1].data
          : formik?.values?.benefits;
      case "workingHours":
        return recWorkingHours?.length > 0
          ? recWorkingHours[recWorkingHours?.length - 1].data
          : formik?.values?.workingHours;
      case "interviewPro":
        return recInterviewPro?.length > 0
          ? recInterviewPro[recInterviewPro?.length - 1].data
          : formik?.values?.interviewPro;
      case "recNotes":
        return recRecruiterNotes?.length > 0
          ? recRecruiterNotes[recRecruiterNotes?.length - 1].data
          : formik?.values?.recNotes;

      default:
        return formik?.values?.JobDes;
    }
  };

  const generateJobSectionAI = async (sectionName, field, promptValue) => {
    const jobDescription = formik?.values?.JobDes;

    const valueToGenerate = getValueToSend(field);
    const formData = new FormData();
    formData.append("job_description", jobDescription);
    formData.append("section_name", sectionName);
    formData.append("value", valueToGenerate);
    formData.append("to_update", promptValue);

    try {
      await API.post("/job-board/regenerate-job-section", formData)
        .then((response) => {
          console.log("response ====>", response);
          if (response?.status === 200 || response?.status === 201) {
            let res = response?.data?.data;
            if (response?.data?.result === true) {
              updateRecommendArray(field, res?.value, promptValue);
            }
          }
        })
        .catch((err) => console.log(err));
    } catch (error) {
      console.log(error);
    }
  };

  const regenerateBtnClickHandler = async (field) => {
    let data = [];
    switch (field) {
      case "JobDes":
        data = recJobDes;
        break;
      case "rules":
        data = recRules;
        break;
      case "skill":
        data = recSkills;
        break;
      case "mustSkill":
        data = recMustSkills;
        break;
      case "niceToHave":
        data = recNiceToHave;
        break;
      case "benefits":
        data = recBenefits;
        break;
      case "workingHours":
        data = recWorkingHours;
        break;
      case "interviewPro":
        data = recInterviewPro;
        break;
      case "recNotes":
        data = recRecruiterNotes;
        break;

      default:
        data = recJobDes;
        break;
    }

    return data;
  };

  const promptClickHandler = async () => {
    if (
      formik?.values?.jobTitle === undefined ||
      formik?.values?.jobTitle === null ||
      formik?.values?.jobTitle === ""
    ) {
      console.log("Job title is undefined");
      formik.touched["jobTitle"] = true;
      isFormFieldValid("jobTitle");
      formik?.setFieldError("jobTitle", "Job Title field cannot be empty");
      return;
    }

    if (
      (formik?.values?.jobPosition === undefined ||
        formik?.values?.jobPosition === null ||
        formik?.values?.jobPosition === "") &&
      formik?.values?.jobPositionHide === false
    ) {
      console.log("Job position is undefined");
      formik.touched["jobPosition"] = true;
      isFormFieldValid("jobPosition");
      formik?.setFieldError("jobPosition", "Please Select A Career Level");
      return;
    }

    /*setPromptMessage("");
                setShowPromptMessageField(false);*/

    const jobTitle = formik?.values?.jobTitle;
    const careerLevel = formik?.values?.jobPosition;
    const promptInput = formik?.values?.promptInput;

    try {
      //dispatch(setProgressModel(true));
      const formData = new FormData();
      formData.append("job_title", jobTitle);
      careerLevel !== "" && formData.append("job_level", careerLevel);
      promptInput !== "" && formData.append("prompt_input", promptInput);
      formData.append("company_id", user?.company_id);
      //formData.append("prompt_input", promptMessage);

      await API.post("/job-board/generate-ai-job-desc-wo-qa", formData)
        .then(async (response) => {
          if (response?.status === 200 || response?.status === 201) {
            let res = response?.data?.data;
            let resMsg = response?.data?.message;
            if (response?.data?.result === true) {
              // using this to show the fields in the jsx
              setPromptMessage("");
              setShowPromptMessageField(false);

              // using this to set the value in the fields
              await formik.setFieldValue(
                "promptInput",
                res?.prompt_input?.toString() ?? ""
              );

              await formik.setFieldValue(
                "JobDes",
                res?.job_summary?.toString() ?? ""
              );

              await formik.setFieldValue("jobTitleId", res?.job_title_id);
              await formik.setFieldValue("JobDesId", res?.job_desc_id ?? null);

              await formik.setFieldValue(
                "rules",
                res?.roles_n_responsibilities?.toString() ?? ""
              );
              await formik.setFieldValue(
                "skill",
                res?.skills?.toString() ?? ""
              );
              await formik.setFieldValue(
                "mustSkill",
                res?.must_have_skills?.toString() ?? ""
              );
              await formik.setFieldValue(
                "niceToHave",
                res?.nice_to_have_skills?.toString() ?? ""
              );
              await formik.setFieldValue(
                "benefits",
                res?.benefits?.toString() ?? ""
              );
              await formik.setFieldValue(
                "workingHours",
                res?.working_hours?.toString() ?? ""
              );
              await formik.setFieldValue(
                "interviewPro",
                res?.interview_process ?? ""
              );
              await formik.setFieldValue(
                "recNotes",
                res?.recruiter_notes ?? ""
              );
              if (res?.key_skill_tags?.length > 0) {
                setSelectSkillTags(res?.key_skill_tags);
              }

              dispatch(setProgressModel(false));
              setShowFields(true);
            } else if (response?.data?.result === false) {
              formik.touched["promptInput"] = true;
              isFormFieldValid("promptInput");
              formik?.setFieldError("promptInput", resMsg?.error);
              return;
            }
          }
        })
        .catch((err) => {
          dispatch(setProgressModel(false));
          console.log("error =======>", err);
        });
    } catch (error) {
      dispatch(setProgressModel(false));
      console.log(error);
    }
  };

  useEffect(() => {
    if (jobDecision === null) {
      cleanAllState();
      setShowFields(false);
    }
  }, [jobDecision]);

  useEffect(() => {
    return () => {
      if (mode === "add") {
        dispatch(clearJobDecision());
        setShowFields(false);
      }
      if (mode === "edit") {
        setShowFields(true);
      }
      setRecJobDes([]);
    };
  }, []);

  const formik = useFormik({
    initialValues: {
      jobTitle: "",
      jobTitleId: null,
      promptInput: "",
      jobPosition: "",
      jobPositionId: null,
      jobPositionHide: false,
      currency: "",
      currencyId: null,
      salaryStart: "",
      salaryEnd: "",
      salaryRange: "",
      jobType: "",
      jobTypeId: null,
      skillTags: "",
      JobDes: "",
      JobDesId: null,
      rules: "",
      skill: "",
      mustSkill: "",
      niceToHave: "",
      benefits: "",
      workingHours: "",
      interviewPro: "",
      recNotes: "",
      reportingTo: "",
      reportToId: null,
      WorkMethodology: "",
      workMethodologyId: null,
      state: "",
      stateId: null,
      city: "",
      cityId: null,
      status: "",
    },
    /*validationscheme*/
    validationSchema: Yup.object({
      jobTitle: Yup.string()
        .required("Please select a job title")
        .max(100, "JOb title cannot exceed 100 characters")
        .matches(jobTitleRegex, "Please enter valid job title")
        .matches(scriptOnlyHTMLTagRegex, "Please enter valid job title"),
      jobPosition: Yup.string(),
      promptInput: Yup.string().matches(
        scriptOnlyHTMLTagRegex,
        "Please enter valid detail"
      ),

      JobDes: Yup.string().when([], {
        is: (value) => is_Job_Copy_Paste,
        then: () =>
          Yup.string()
            .matches(
              scriptOnlyHTMLTagRegex,
              "Please enter valid Job description"
            )
            .required("Job description cannot be empty"),
        otherwise: () =>
          Yup.string()
            .matches(scriptOnlyHTMLTagRegex, "Please enter valid job summary")
            .required("Job summary cannot be empty"),
      }),

      rules: Yup.string().when([], {
        is: (value) => is_Job_Copy_Paste,
        then: () => Yup.string(),
        otherwise: () =>
          Yup.string()
            .matches(
              scriptOnlyHTMLTagRegex,
              "Please enter valid roles and responsibilities"
            )
            .required("Roles and responsibilities cannot be empty"),
      }),

      skill: Yup.string().when([], {
        is: (value) => is_Job_Copy_Paste,
        then: () => Yup.string(),
        otherwise: () =>
          Yup.string()
            .matches(scriptOnlyHTMLTagRegex, "Please enter valid qualification")
            .required("Qualification cannot be empty"),
      }),

      mustSkill: Yup.string().when([], {
        is: (value) => is_Job_Copy_Paste,
        then: () => Yup.string(),
        otherwise: () =>
          Yup.string()
            .matches(
              scriptOnlyHTMLTagRegex,
              "Please enter valid must-have skills"
            )
            .required("Must have skills cannot be empty"),
      }),

      niceToHave: Yup.string().when([], {
        is: (value) => is_Job_Copy_Paste,
        then: () => Yup.string(),
        otherwise: () =>
          Yup.string().matches(
            scriptOnlyHTMLTagRegex,
            "Please enter valid nice to have"
          ),
      }),

      benefits: Yup.string().when([], {
        is: (value) => is_Job_Copy_Paste,
        then: () => Yup.string(),
        otherwise: () =>
          Yup.string()
            .matches(scriptOnlyHTMLTagRegex, "Please enter valid benefits")
            .required("Benefits cannot be empty"),
      }),

      workingHours: Yup.string().when([], {
        is: (value) => is_Job_Copy_Paste,
        then: () => Yup.string(),
        otherwise: () =>
          Yup.string()
            .matches(scriptOnlyHTMLTagRegex, "Please enter valid working hours")
            .required("Working hours cannot be empty"),
      }),

      recNotes: Yup.string().matches(
        scriptOnlyHTMLTagRegex,
        "Please enter valid recruiter notes"
      ),
      currency: Yup.string().required("Please select a currency"),
      salaryStart: Yup.string()
        .matches(numericWithCommaAndDecimal, "Please enter a numeric value.")
        .max(12, "Lower salary cannot exceed 12 characters")
        .typeError("Please enter a numeric value.")
        .test({
          name: "validNumber",
          message: "Please enter a numeric value between 0 and 9.",
          test: (value) => {
            if (value) {
              const numValue = parseFloat(String(value).replace(/,/g, ""));
              return numValue >= 0;
            }
            return true;
          },
        }),

      salaryEnd: Yup.string()
        .matches(numericWithCommaAndDecimal, "Please enter a numeric value.")
        .max(12, "Upper salary cannot exceed 12 characters")
        .typeError("Please enter a numeric value.")
        .when("salaryStart", (salaryStart, schema) => {
          return schema.test({
            name: "greaterThanSalaryStart",
            test: function (salaryEnd) {
              if (salaryStart && salaryEnd) {
                const startValue = parseFloat(
                  String(salaryStart).replace(/,/g, "")
                );
                const endValue = parseFloat(
                  String(salaryEnd).replace(/,/g, "")
                );
                if (endValue <= startValue) {
                  return this.createError({
                    message:
                      "Upper salary amount should not be less than lower salary amount.",
                    path: "salaryEnd",
                  });
                }
              }
              return true;
            },
          });
        }),
      salaryRange: Yup.string().required("Please select a salary range"),
      jobType: Yup.string().required("Please select a job type"),
      state: Yup.string().required("Please select state"),
      city: Yup.string().test(
        "city-required",
        "Please select a city",
        function (value) {
          const { city } = this.parent;
          if (cityOption && cityOption.length > 0 && !city) {
            return !!value;
          }
          return true;
        }
      ),
    }),
    context: { cityOption },
    onSubmit: async (data, { resetForm }) => {
      const SkillTags = selectSkillTags?.map((item) => item?.name);
      const hiringStepsId = checked?.map((item) => item.id);
      let jobObj = {
        data: data,
        SkillTags: SkillTags,
        hiringSteps: hiringStepsId,
      };
      localStorage.setItem("jobData", JSON.stringify(jobObj));
      const searchParams = new URLSearchParams(location.search);
      const isEdit = searchParams.get("edit");
      if (formik.values.status === "published") {
        proceedToJob();
      } else {
        postAJobHandler(data, SkillTags, hiringStepsId);
      }
    },
  });

  const proceedToJob = () => {
    setPostingJob(!postingJob);
    const getJobData = JSON.parse(localStorage.getItem("jobData"));
    postAJobHandler(
      getJobData.data,
      getJobData.SkillTags,
      getJobData.hiringSteps,
      "/employer-dashboard"
    );
    localStorage.removeItem("jobData");
  };

  const isFormFieldValid = (name) =>
    !!(formik.touched[name] && formik.errors[name]);
  const getFormErrorMessage = (name) => {
    return (
      isFormFieldValid(name) && (
        <p className="error-msg">{formik.errors[name]}</p>
      )
    );
  };

  const isArrayFieldValid = (passwordErrors) =>
    !!(formik.touched[passwordErrors] && formik.errors[passwordErrors]);
  const getArrayErrorMessage = (passwordErrors) => {
    if (isArrayFieldValid(passwordErrors)) {
      return (
        <ul className="error-msg">
          {passwordErrors.map((error, index) => (
            <li key={index}>{error}</li>
          ))}
        </ul>
      );
    }
    return null;
  };

  const scrollToField = (fieldName) => {
    const fieldRef = fieldRefs[fieldName];
    if (fieldRef?.current) {
      fieldRef.current.scrollIntoView({ behavior: "smooth", block: "center" });
      fieldRef.current.focus();
    }
  };

  const scrollToHead = () => {
    if (headRef.current) {
      headRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const onjobTitleChange = async (e) => {
    const { value } = e?.target;
    let lowercaseJobTitle = value?.toLowerCase();

    let hasKeyword = POST_JOB_TITLE?.some((keyword) =>
      lowercaseJobTitle.includes(keyword?.toLowerCase())
    );
    let hideJobpostion = hasKeyword;

    await formik.setFieldValue("jobTitle", value);

    if (hideJobpostion) {
      await formik.setFieldValue("jobPositionHide", hideJobpostion);
      await formik.setFieldValue("jobPositionId", null);
      await formik.setFieldValue("jobPosition", "");
      await formik.setFieldValue("jobTitleId", null);
    } else {
      await formik.setFieldValue("jobPositionHide", false);
      await formik.setFieldValue("jobTitleId", null);
    }
  };

  const onJobPositionClick = async (id, name) => {
    await formik.setFieldValue("jobPositionId", id);
    await formik.setFieldValue("jobPosition", name);
  };

  const onSalaryRangeClick = async (name) => {
    await formik.setFieldValue("salaryRange", name);
  };

  const onCurrencyClick = async (id, name) => {
    await formik.setFieldValue("currencyId", id);
    await formik.setFieldValue("currency", name);
  };

  const onJobTypeClick = async (id, name) => {
    await formik.setFieldValue("jobTypeId", id);
    await formik.setFieldValue("jobType", name);
  };

  const onReportToClick = async (id, name) => {
    await formik.setFieldValue("reportToId", id);
    await formik.setFieldValue("reportingTo", name);
  };

  const onWorkMethClick = async (id, name) => {
    await formik.setFieldValue("workMethodologyId", id);
    await formik.setFieldValue("WorkMethodology", name);
  };

  const onStateClick = async (id, name) => {
    await formik.setFieldValue("stateId", id);
    await formik.setFieldValue("state", name);

    await formik.setFieldValue("cityId", null);
    await formik.setFieldValue("city", "");
    formik.setFieldError("city", "");

    getCitiesData(null, id);
    setStateDDHide(true);
  };

  const onCitiesClick = async (id, name) => {
    const newCity = { id: id, name: name };
    await formik.setFieldValue("cityId", id);
    await formik.setFieldValue("city", name);
    setCityDDHide(true);
  };

  const onSkillTagsChange = async (value) => {
    await formik.setFieldValue("skillTags", value);
    getSearchSuggestion(encodeURIComponent(value), "jobTag");
  };

  const onSkillTagsClick = (currentSkillTag) => {
      // Trim the input and check if it's only numbers
     if (!isNaN(currentSkillTag?.trim())) return; // Prevent numeric-only tags

    const newSkill = { id: Math?.random(), name: currentSkillTag };

    const isNameExists = selectSkillTags?.some(
      (item) => item?.name?.toLowerCase() === newSkill?.name?.toLowerCase()
    );
    setAllSkillTagsHide(true);
    formik.setFieldValue("skillTags", "");
    if (isNameExists) return; // Prevent duplicates
    setSelectSkillTags([...selectSkillTags, newSkill]);    
  };

  const onSkillTagsCancel = (currentIndex) => {
    const updatedItems = selectSkillTags?.filter(
      (item, index) =>
        index !== currentIndex
    );
    setSelectSkillTags(updatedItems);
  };

  const handleSkillTagsKeyDown = (e) => {
    if (e?.key === "Enter" && e?.currentTarget?.value?.trim() !== "") {
      onSkillTagsClick(e?.currentTarget?.value?.trim()); // Add the custom tag
      e.preventDefault();
    }
  };

  /* State search */

  const filteredStateItem = stateOption?.filter((item) =>
    item?.name?.toLowerCase().includes(stateSearch.toLowerCase())
  );

  const searchStateLocation = (e) => {
    setStateSearch(e.target.value);
  };

  /********************/

  /* City search */

  const filteredCityItem = cityOption?.filter((item) =>
    item?.name?.toLowerCase().includes(citySearch.toLowerCase())
  );

  const searchCityLocation = (e) => {
    setCitySearch(e.target.value);
  };

  /********************/

  const getSearchSuggestion = async (searchValue, searchType) => {
    try {
      let params = ``;
      if (searchValue !== "") {
        params = `?query_key=${searchValue}&type=${searchType}`;
      }
      await API.get(`/job-board/get-search-suggestions${params}`)
        .then((response) => {
          if (response?.status === 200) {
            let res = response?.data;

            if (searchType === "jobTag") {
              if (res?.job_tags?.length > 0) {
                setAllSkillTags(res?.job_tags);
                setAllSkillTagsHide(false);
              } else {
                setAllSkillTags([]);
                setAllSkillTagsHide(false);
              }
            }
          }
        })
        .catch((error) => {
          if (error) {
            console.log(error);
          }
        });
    } catch (error) {
      console.log(error);
    }
  };

  const getJobTitles = async (searchValue, searchType) => {
    try {
      let params = ``;
      if (searchValue !== "") {
        params = `?${searchType}=${searchValue}`;
      }

      await API.get(`/job-board/job-titles-careers${params}`)
        .then(async (response) => {
          if (response?.status === 200) {
            let res = response?.data?.data;
            //   setJobTitleOption(res?.job_titles);
            setCareerOption(res?.career_levels);

            // if (res?.job_titles?.length === 0) {
            //   // console.log("No Job titles found");

            //   // display error message on job title
            //   formik.touched["jobTitle"] = true;
            //   formik.errors["jobTitle"] =
            //     "Job Title does not exist in our system";
            //   await formik.setFieldValue("jobTitleId", null);
            //   // show Job decision modal dialog
            //   //     dispatch(openJobDecisionModal());
            // }
          }
        })
        .catch((error) => {
          if (error) {
            console.log(error);
          }
        });
    } catch (error) {
      console.log(error);
    }
  };

  const getJobDescriptionData = async () => {
    if (formik?.values?.jobTitle !== "") {
      try {
        dispatch(setProgressModel(true));
        promptClickHandler();

        // let jobDecisionValue = "";
        // if (jobDecision === JOB_POST_DECISION.SYSTEM_GEN)
        //   jobDecisionValue = "system_desc";
        // else if (jobDecision === JOB_POST_DECISION.COPY_PASTE)
        //   jobDecisionValue = "self_desc";
        // else if (jobDecision === JOB_POST_DECISION.ZOE)
        //   jobDecisionValue = "zoe_desc";

        // let params = `?`;
        // if (formik?.values?.jobTitle !== "") {
        //   params += `job_title=${formik?.values?.jobTitle}&`;
        // }
        // if (formik?.values?.jobPositionId !== null) {
        //   params += `career_level_id=${formik?.values?.jobPositionId}&`;
        // }
        // if (user !== null) {
        //   params += `company_id=${user?.company_id}&`;
        // }
        // if (jobDecisionValue !== "") {
        //   params += `desc_generation_method=${jobDecisionValue}&`;
        // }
        // dispatch(setProgressModel(true));
        // await API.get(`/job-board/job-description-formatted${params}`)
        //   .then(async (response) => {
        //     if (response?.status === 200) {
        //       let res = response?.data?.data;
        //       let result = response?.data;

        //       if (result?.success === true) {
        //         setShowFields(true);
        //         await formik.setFieldValue(
        //           "JobDes",
        //           res?.description !== null ? res?.description : ""
        //         );

        //         await formik.setFieldValue("jobTitleId", res?.job_title_id);
        //         await formik.setFieldValue("JobDesId", res?.id);
        //         await formik.setFieldValue("rules", res?.responsibilities);
        //         await formik.setFieldValue("skill", res?.skills);
        //         await formik.setFieldValue("mustSkill", res?.must_skills);
        //         await formik.setFieldValue("niceToHave", res?.nice_to_have);
        //         await formik.setFieldValue("benefits", res?.benefits);
        //         await formik.setFieldValue("workingHours", res?.working_hrs);
        //         await formik.setFieldValue("recNotes", res?.recruiter_notes);

        //         if (res?.job_tags?.length > 0) {
        //           setSelectSkillTags(res?.job_tags);
        //         }

        //         setTimeout(() => {
        //           dispatch(setProgressModel(false));
        //         }, 5000);
        //       } else if (
        //         result?.success === false &&
        //         Object.keys(res).length === 0
        //       ) {
        //         promptClickHandler();
        //         // formik.touched["jobTitle"] = true;
        //         // await formik.setFieldValue("jobTitleId", null);
        //         // formik.setFieldError(
        //         //   "jobTitle",
        //         //   "Job Title does not exist in our system"
        //         // );
        //         //    dispatch(openJobDecisionModal());
        //         return;
        //       }
        //     }
        //   })
        //   .catch(async (error) => {
        //     if (error) {
        //       const { response } = error;
        //       const { data } = response;
        //       console.log(error);
        //     }
        //   });
      } catch (error) {
        console.log(error);
      }
    }
  };

  const cleanState = async () => {
    setRecJobDes([]);
    setRecRules([]);
    setRecSkills([]);
    setRecMustSkills([]);
    setRecNiceToHave([]);
    setRecBenefits([]);
    setRecWorkingHours([]);
    setRecInterviewPro([]);
    setRecRecruiterNotes([]);

    setJobDesPrompt("");
    setRulesPrompt("");
    setSkillsPrompt("");
    setMustSkillsPrompt("");
    setNiceToHavePrompt("");
    setBenefitsPrompt("");
    setWorkingHoursPrompt("");
    setInterviewProPrompt("");
    setRecruiterNotesPrompt("");
    setJobDesPrompt("");
  };

  const cleanAllState = async () => {
    /*Formik reset Fields*/
    formik.resetForm();

    /*Other reset Fields*/
    if (jobDecision !== JOB_POST_DECISION.COPY_PASTE) setShowFields(false);
    setSelectSkillTags([]);

    setRecJobDes([]);
    setRecRules([]);
    setRecSkills([]);
    setRecMustSkills([]);
    setRecNiceToHave([]);
    setRecBenefits([]);
    setRecWorkingHours([]);
    setRecInterviewPro([]);
    setRecRecruiterNotes([]);

    setJobDesPrompt("");
    setRulesPrompt("");
    setSkillsPrompt("");
    setMustSkillsPrompt("");
    setNiceToHavePrompt("");
    setBenefitsPrompt("");
    setWorkingHoursPrompt("");
    setInterviewProPrompt("");
    setRecruiterNotesPrompt("");
    setJobDesPrompt("");

    /*redux value reset*/
    // dispatch(clearJobDecision());
    getDropdownData();
  };

  const generateJobDescription = async () => {
    const { jobTitle, jobPosition, jobPositionHide } = formik.values;
    if (jobTitle === "") {
      formik.touched["jobTitle"] = true;
      isFormFieldValid("jobTitle");
      formik?.setFieldError("jobTitle", "Please select a job title");
    }
    if(!is_Job_Copy_Paste){
      if (jobPosition === "" && jobPositionHide === false) {
        formik.touched["jobPosition"] = true;
        formik?.setFieldError("jobPosition", "Please select a job level");
      }
      if (jobTitle === "" || (jobPosition === "" && jobPositionHide === false))
        return;
    }   
    await cleanState();
    await getJobDescriptionData();
  };

  const getCitiesData = async (id1, id2) => {
    dispatch(
      getCitiesAsync({
        country_id: id1,
        state_id: id2,
      })
    )
      .then((response) => {
        const res = response?.payload;
        setCityOption(res);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getDropdownData = () => {
    if (mode === "edit" && selectedJobCountry !== null && postJobId !== null) {
      getJobDropDownData(selectedJobCountry);
    } else if (mode === "add" && userCountry?.id !== null) {
      getJobDropDownData(userCountry?.id);
    } else {
      getJobDropDownData(userCountry?.id);
    }
  };

  const getJobDropDownData = async (id) => {
    if (user !== null && id !== null) {
      try {
        await API.get(`/job-board/post-a-job/detail?country_id=${id}`)
          .then(async (response) => {
            if (response?.status === 200) {
              let res = response?.data?.data;
              let result = response?.data;
              if (result?.success === true) {
                setCurrencyOption(res?.currencies);
                setSalaryRangeOption(res?.salary_ranges);
                setJobTypeOption(res?.job_types);
                setReportingToOption(res?.reporting_persons);
                setWorkMethOption(res?.work_methodologies);
                setStateOption(res?.states);
                if (mode !== "edit") {
                  setCityOption(res?.cities);
                }
                setHiringSteps(res?.hiring_steps);

                await formik.setFieldValue(
                  "currencyId",
                  res?.currencies[0]?.id
                );
                await formik.setFieldValue(
                  "currency",
                  res?.currencies[0]?.title
                );
                await formik.setFieldValue(
                  "salaryRange",
                  res?.salary_ranges[0]?.value
                );
              }
            }
          })
          .catch((error) => {
            if (error) {
              console.log(error);
            }
          });
      } catch (error) {
        console.log(error);
      }
    }
  };

  // checked.some((item) => item.id === id)
  const isItemInHiringArray = (itemId) => {
    return checked.some((item) => item.id === itemId);
  };

  useEffect(() => {
    getJobTitles("", "search");
  }, []);

  useEffect(() => {
    getDropdownData();
  }, []);

  /********* Post Now **********/

  const onPostNowClick = async () => {
    const isFormValid = await formik.validateForm();

    if (Object.keys(isFormValid).length > 0) {
      // Scroll to the first field with an error
      const firstErrorField = Object.keys(isFormValid)[0];
      scrollToField(firstErrorField);

      // Mark all fields as touched
      formik.setTouched(
        Object.keys(isFormValid).reduce((acc, key) => {
          acc[key] = true;
          return acc;
        }, {})
      );
    } else {
      formik.handleSubmit();
    }
  };

  /********Check Biases********/

  const jobCheckBiases = async () => {
    const isFormValid = await formik.validateForm();

    if (Object.keys(isFormValid).length === 0) {
      try {
        const {
          jobTitle,
          jobPositionId,
          JobDes,
          rules,
          skill,
          mustSkill,
          niceToHave,
          benefits,
          workingHours,
          interviewPro,
          recNotes,
        } = formik.values;

        const formData = new FormData();
        formData.append("name", jobTitle);
        jobPositionId !== null &&
          formData.append("career_level_id", jobPositionId);
        formData.append("description", JobDes);
        formData.append("responsibilities", rules);
        formData.append("skills", skill);
        formData.append("must_skills", mustSkill);
        formData.append("nice_to_have", niceToHave);
        formData.append("benefits", benefits);
        formData.append("working_hrs", workingHours);
        formData.append("interview_process", interviewPro);
        formData.append("recruiter_notes", recNotes);
        dispatch(openDivModal());
        await API.post(`/job-board/check-biases`, formData)
          .then(async (response) => {
            if (response?.status === 200 || response?.status === 201) {
              let res = response?.data?.job_description;
              let result = response?.data;
              if (result?.success === true) {
                await formik.setFieldValue("JobDes", res?.description);
                await formik.setFieldValue(
                  "rules",
                  await res?.responsibilities
                );
                await formik.setFieldValue("skill", await res?.skills);
                await formik.setFieldValue("mustSkill", await res?.must_skills);
                await formik.setFieldValue(
                  "niceToHave",
                  await res?.nice_to_have
                );
                await formik.setFieldValue("benefits", await res?.benefits);
                await formik.setFieldValue(
                  "workingHours",
                  await res?.working_hrs
                );
                await formik.setFieldValue(
                  "interviewPro",
                  await res?.interview_process
                );
                await formik.setFieldValue(
                  "recNotes",
                  await res?.recruiter_notes
                );
              } else {
                if (response?.message?.error !== "") {
                  handleYesButtonClick(false);
                  dispatch(closeDivModal());
                  toast.error(response?.message?.error, {
                    autoClose: 2000,
                    closeOnClick: false,
                  });
                }
              }
              handleYesButtonClick(false);
              dispatch(closeDivModal());

              if (Object.keys(isFormValid).length > 0) {
                // Scroll to the first field with an error
                const firstErrorField = Object.keys(isFormValid)[0];
                scrollToField(firstErrorField);

                // Mark all fields as touched
                formik.setTouched(
                  Object.keys(isFormValid).reduce((acc, key) => {
                    acc[key] = true;
                    return acc;
                  }, {})
                );
              }
            }
          })
          .catch((error) => {
            const { response } = error;
            const { data } = response;
            handleYesButtonClick(false);
            dispatch(closeDivModal());
            toast.error(data?.message?.error, {
              autoClose: 2000,
              closeOnClick: false,
            });
            console.log(error);
          });
      } catch (error) {
        console.log(error);
      }
    } else {
      if (Object.keys(isFormValid).length > 0) {
        // Scroll to the first field with an error
        const firstErrorField = Object.keys(isFormValid)[0];
        scrollToField(firstErrorField);

        // Mark all fields as touched
        formik.setTouched(
          Object.keys(isFormValid).reduce((acc, key) => {
            acc[key] = true;
            return acc;
          }, {})
        );
      }
    }
    handleYesButtonClick(false);
    dispatch(closeDivModal());
  };

  useEffect(() => {
    if (handleYesButtonPostNow) {
      jobCheckBiases();
    }
  }, [handleYesButtonPostNow]);

  /************************/

  useEffect(() => {
    if (mode === "edit" && postJobId !== null) {
      async function getJobDetail() {
        try {
          setSpinner(true);
          await API.get(`/job-board/get-job-detail/${postJobId}`)
            .then(async (response) => {
              if (response?.status === 200) {
                let res = response?.data?.data;
                let result = response?.data;
                let lowercaseJobTitle = res?.name?.toLowerCase();

                let hasKeyword = POST_JOB_TITLE?.some((keyword) =>
                  lowercaseJobTitle.includes(keyword?.toLowerCase())
                );
                let hideJobpostion = hasKeyword;

                if (result?.success === true) {              
                  await formik.setFieldValue("jobTitle", res?.name);
                  setChecked(res?.hiring_steps);
                  await formik.setFieldValue("jobTitleId", res?.job_title_id);

                  if (hideJobpostion || res?.generation_method === JOB_GEN_METHOD.SELF_DESC) {
                    await formik.setFieldValue("jobPositionId", null);

                    await formik.setFieldValue("jobPosition", "");

                    await formik.setFieldValue(
                      "jobPositionHide",
                      hideJobpostion
                    );
                  } else {
                    await formik.setFieldValue(
                      "jobPositionId",
                      res?.career_level?.id
                    );

                    await formik.setFieldValue(
                      "jobPosition",
                      res?.career_level?.name
                    );
                    await formik.setFieldValue("jobPositionHide", false);
                  }


                  if(res?.generation_method !== JOB_GEN_METHOD.SELF_DESC){
                    await formik.setFieldValue(
                      "promptInput",
                      res?.prompt_input?.toString() ?? ""
                    );
                    await formik.setFieldValue(
                      "rules",
                      await res?.responsibilities
                    );
                    await formik.setFieldValue("skill", await res?.skills);
                    await formik.setFieldValue(
                      "mustSkill",
                      await res?.must_skills
                    );
                    await formik.setFieldValue(
                      "niceToHave",
                      await res?.nice_to_have
                    );
                    await formik.setFieldValue("benefits", await res?.benefits);
                    await formik.setFieldValue(
                      "workingHours",
                      await res?.working_hrs
                    );
                    await formik.setFieldValue(
                      "interviewPro",
                      await res?.interview_process
                    );
                    await formik.setFieldValue(
                      "reportToId",
                      res?.report_to?.id ? res?.report_to?.id : null
                    );
                    await formik.setFieldValue(
                      "reportingTo",
                      res?.report_to?.name ? res?.report_to?.name : ""
                    );
  
                    await formik.setFieldValue(
                      "workMethodologyId",
                      res?.work_methodology?.id ? res?.work_methodology?.id : null
                    );
                    await formik.setFieldValue(
                      "WorkMethodology",
                      res?.work_methodology?.name
                        ? res?.work_methodology?.name
                        : ""
                    );
                  }
                                 
                  await formik.setFieldValue("JobDes", res?.description);
                  await formik.setFieldValue("JobDesId", res?.job_desc_id);

                  await formik.setFieldValue(
                    "recNotes",
                    (await res?.recruiter_notes) ?? ""
                  );

                  await formik.setFieldValue("currencyId", res?.currency?.id);
                  await formik.setFieldValue("currency", res?.currency?.title);

                  await formik.setFieldValue(
                    "salaryStart",
                    res?.salary_from ? res?.salary_from : ""
                  );
                  await formik.setFieldValue(
                    "salaryEnd",
                    res?.salary_to ? res?.salary_to : ""
                  );
                  await formik.setFieldValue(
                    "salaryRange",
                    res?.salary_range !== null ? res?.salary_range : ""
                  );
                  await formik.setFieldValue(
                    "jobTypeId",
                    res?.job_types[0]?.id ? res?.job_types[0]?.id : null
                  );
                  await formik.setFieldValue(
                    "jobType",
                    res?.job_types[0]?.name ? res?.job_types[0]?.name : ""
                  );
                  setSelectSkillTags(res?.tags);                

                  await formik.setFieldValue(
                    "stateId",
                    res?.state?.id ? res?.state?.id : null
                  );
                  await formik.setFieldValue(
                    "state",
                    res?.state?.name ? res?.state?.name : ""
                  );

                  await formik.setFieldValue(
                    "cityId",
                    res?.cities[0]?.id ? res?.cities[0]?.id : null
                  );
                  await formik.setFieldValue(
                    "city",
                    res?.cities[0]?.name ? res?.cities[0]?.name : ""
                  );

                  setSelectedJobCountry(res?.country_id);
                  if (mode === "edit" && res?.country_id !== null) {
                    getJobDropDownData(res?.country_id);
                  }
                  if (
                    mode === "edit" &&
                    res?.state &&
                    res?.state?.id !== null
                  ) {
                    getCitiesData(null, res?.state?.id);
                  }

                  if (res?.generation_method === JOB_GEN_METHOD.SYSTEM_DESC) {
                    dispatch(changeJobDecisionToSyetemGen());
                  } else if (res?.generation_method === JOB_GEN_METHOD.SELF_DESC) {
                    dispatch(changeJobDecisionToCopy());
                  } else if (res?.generation_method === JOB_GEN_METHOD.ZOE_DESC) {
                    dispatch(changeJobDecisionToZoe());
                    setShowPromptMessageField(true);
                  }
                  await formik.setFieldValue("status", res?.status);
                  setShowFields(true);
                }
              }
              setSpinner(false);
            })
            .catch((error) => {
              setSpinner(false);
              console.log(error);
            });
        } catch (error) {
          setSpinner(false);
          console.log(error);
        }
      }

      getJobDetail();
    }
  }, [postJobId]);

  const handleCheckboxChange = (event, item, id) => {
    const { checked: isChecked } = event.target;
    if (isChecked) {
      setChecked((prevItems) => [...prevItems, item]);
    } else {
      setChecked((prevItems) => prevItems.filter((i) => i.id !== item.id));
    }
  };

  const saveNewStage = async () => {
    if (newStagesValue !== "") {
      let formData = {
        user_id: user.id,
        step_name: newStagesValue,
      };
      try {
        await API.post(`/job-board/add-hiring-step`, formData).then((res) => {
          if (res.data.result) {
            setCustomStage(!customStage);
            toast.success(res.data.message.success, {
              autoClose: 2000,
              closeOnClick: false,
            });
            getDropdownData();
          }
        });
      } catch (error) {
        if (error) {
          toast.error(error.response.data.message.error, {
            autoClose: 2000,
            closeOnClick: false,
          });
          console.log(error);
        }
      }
    }
  };

  return (
    <>
      {spinner ? (
        <div className="loader-div">
          <span
            className="spinner-border spinner-border"
            role="status"
            aria-hidden="true"
          />
        </div>
      ) : (
        <>
          {mode === "add" && jobDecision === null ? (
            <section className={`post-card-sec-bg`} ref={headRef}>
              <div className="container container-site">
                <div className="main-row-job-card">
                  <div className="left-job-content-postcard">
                    <p className="ai-content-post">
                      PolyCareers AI-powered job descriptions
                    </p>
                    <p className="create-post-heading-post">
                      Create your job posting easily.
                    </p>
                    <p className="post-card-content-ai">
                      Craft your job description effortlessly with the option to
                      cut and paste your own or utilize PolyCareers AI tools for
                      quick and easy assistance. It's simple, seamless, and
                      entirely up to you!
                    </p>
                    <div className="two-ai-post-btn">
                      <button
                        className="copy-paste-btn"
                        onClick={() => {
                          dispatch(changeJobDecisionToCopy());
                        }}
                      >
                        Copy & Paste
                      </button>
                      <button
                        className="generate-job-btn"
                        onClick={() => {
                          dispatch(changeJobDecisionToSyetemGen());
                          setChecked([]);
                        }}
                      >
                        Generate job description
                      </button>
                    </div>
                  </div>

                  <div className="right-col-Aimage">
                    <img src="/image/ai-girl.png" alt="" />
                  </div>
                </div>
              </div>
            </section>
          ) : (
            <>
              <section>
                <div className="container container-site">
                  <div className="post-job-row-2 position-relative">
                    <Button
                      variant="link"
                      className="back-to-home add"
                      onClick={() => {
                        if (mode === "add" && jobDecision !== null) {
                          dispatch(clearJobDecision());
                        } else {
                          navigate("/employer-dashboard");
                        }
                      }}
                    >
                      <img src="/image/form-back-arrow.png" alt="" />
                    </Button>
                  </div>
                </div>
              </section>
              <section className="post-card-third-bg">
                <div className="container container-site">
                  <div className="post-job-title-heading">
                    <div className="job-title-joourny-post">
                      {jobDecision === null &&
                        "Create Job Posting - Your Next Team Member Awaits!"}
                      {jobDecision === JOB_POST_DECISION.COPY_PASTE &&
                        "Copy & Paste job description"}
                      {jobDecision === JOB_POST_DECISION.SYSTEM_GEN &&
                        "Instant job description creator"}
                      {jobDecision === JOB_POST_DECISION.ZOE &&
                        "Instant job description creator"}
                    </div>
                    <div className="jour-div-e"></div>
                  </div>
                </div>
              </section>
              <div className="container container-site">
                <div className="job-content-show-right">
                  <div className={"employe-final-job-post form-center-job"}>
                    <div className="final-post-employe">
                      <form onSubmit={formik.handleSubmit}>
                        <div
                          className="reset-f-buttn-img f-feild-post"
                          ref={fieldRefs.jobTitle}
                        >
                          <div className="reset-img-btn">
                            <div
                              className={`signup-jobs-option f-feild-post-first ${
                                isFormFieldValid("jobTitle") && "error-input"
                              }`}
                            >
                              <label for="fjobtitles">Enter job title</label>
                              <br />

                              <div className="industry-feild-frm bg-pass-f">
                                <input
                                  className="company-form-dropdownBox-kit"
                                  type="text"
                                  id="job-post-field"
                                  placeholder="Enter job title"
                                  name="jobTitle"
                                  value={formik?.values?.jobTitle}
                                  onChange={onjobTitleChange}
                                  onBlur={formik.handleBlur}
                                />
                                <div className="icon-hide">
                                  {isFormFieldValid("jobTitle") && (
                                    <img src="/image/warning.png" alt="" />
                                  )}
                                </div>
                              </div>
                            </div>
                            <button
                              type="button"
                              className="job-reset-btn-f"
                              onClick={() => cleanAllState()}
                            >
                              <img
                                src="/image/reset-btn-frm.png"
                                alt="reset-btn-frm"
                              />
                            </button>
                          </div>
                          {getFormErrorMessage("jobTitle")}
                        </div>
                        {formik?.values?.jobPositionHide === false && !is_Job_Copy_Paste && (
                          <div className="f-feild" ref={fieldRefs.jobPosition}>
                            <div className="job-postion-form">
                              <div className="level-of-developer">
                                {careerOption?.length > 0 &&
                                  careerOption.map((prod) => (
                                    <div className="job-postion-select-btn">
                                      <input
                                        id="job-postion"
                                        value="junior"
                                        checked={
                                          formik?.values?.jobPosition ===
                                          prod?.name
                                        }
                                        name="jobPosition"
                                        type="radio"
                                        onClick={() =>
                                          onJobPositionClick(
                                            prod?.id,
                                            prod?.name
                                          )
                                        }
                                      />{" "}
                                      <label
                                        for="job-postion"
                                        className="job-postion-btn"
                                      >
                                        {prod?.name}
                                      </label>
                                    </div>
                                  ))}
                              </div>
                            </div>
                            {getFormErrorMessage("jobPosition")}
                          </div>
                        )}

                        {jobDecision !== JOB_POST_DECISION.COPY_PASTE && (
                          <>
                            <div
                              ref={fieldRefs.promptInput}
                              className={`f-feild-post ${
                                isFormFieldValid("promptInput") && "error-input"
                              }`}
                            >
                              <label for="flink">Give me more detail</label>
                              <br />
                              <textarea
                                rows="3"
                                cols="100"
                                className="form-font-f"
                                name="promptInput"
                                placeholder="Give me more detail"
                                value={formik?.values?.promptInput}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                              />
                              {getFormErrorMessage("promptInput")}
                            </div>
                            <div
                              className={`f-feild-post ${
                                showFields === true && "job-textarea-box"
                              }`}
                            >
                              <div className="job-des-d">
                                <button
                                  type="button"
                                  // disabled={
                                  //   promptMessage === "" ||
                                  //   promptMessage === undefined ||
                                  //   promptMessage === null
                                  // }
                                  className={`job-des-btn ${
                                    promptMessage && "icon-cursor"
                                  }`}
                                  onClick={() => generateJobDescription()}
                                >
                                  {showFields === false
                                    ? "Create new"
                                    : "Update"}
                                </button>
                              </div>
                            </div>
                          </>
                        )}

                        {jobDecision === JOB_POST_DECISION.COPY_PASTE ||
                        showFields === true ? (
                          <>
                            {is_Job_Copy_Paste ? (
                              <>
                                <div className="job-textarea-box-top-m"></div>
                                <div
                                  ref={fieldRefs.JobDes}
                                  className={`signup-company-information job-textarea-box f-feild ${
                                    isFormFieldValid("JobDes") && "error-input"
                                  }`}
                                >
                                  <label for="flink">Job description</label>
                                  <br />

                                  <ReactQuill
                                    theme="snow"
                                    className={`job-des-copy-paste ${
                                      isFormFieldValid("JobDes") &&
                                      "error-input"
                                    }`}
                                    value={formik?.values?.JobDes ?? ""}
                                    placeholder="Copy/Paste your job description and use the Edit tools to bold your headings."
                                    name="JobDes"
                                    modules={modules}
                                    formats={allFormats}
                                    onChange={(data) => {
                                      formik.setFieldValue("JobDes", data);
                                      formik.handleChange("JobDes");
                                    }}
                                    onBlur={() => {
                                      formik.handleBlur("JobDes");
                                      // console.log("formik data ===>", formik.values.JobDes);
                                    }}
                                  />

                                  {getFormErrorMessage("JobDes")}
                                  {/* Recommended Section Start */}
                                  {/* <RecommendedSection
                    data={recJobDes}
                    field={"JobDes"}
                    promptText={jobDesPrompt}
                    fieldNewValue={jobDesNewValue}
                    onRadioButtonChange={onRadioButtonChange}
                    radioFieldName={"jobDescription"}
                    regenerateBtnClickHandler={regenerateBtnClickHandler}
                    updateBtnHandler={updateBtnHandler}
                    generateJobSectionAI={generateJobSectionAI}
                    setFieldPrompt={setFieldPrompt}
                    clearRecommendArray={clearRecommendArray}
                  /> */}
                                  {/* Recommended Section End */}
                                </div>
                              </>
                            ) : (
                              <>                         
                                <div
                                  ref={fieldRefs.JobDes}
                                  className={`signup-company-information job-textarea-box f-feild ${
                                    isFormFieldValid("JobDes") && "error-input"
                                  }`}
                                >
                                  <label for="flink">Job summary</label>
                                  <br />

                                  <ReactQuill
                                    theme="snow"
                                    className={`${
                                      isFormFieldValid("JobDes") &&
                                      "error-input"
                                    }`}
                                    value={formik?.values?.JobDes ?? ""}
                                    placeholder="Provide a brief, high-level summary of the job role. Highlight the key objectives and purpose of the position."
                                    name="JobDes"
                                    modules={modules}
                                    formats={formatsP}
                                    onChange={(data) => {
                                      formik.setFieldValue("JobDes", data);
                                      formik.handleChange("JobDes");
                                    }}
                                    onBlur={() => {
                                      formik.handleBlur("JobDes");
                                      // console.log("formik data ===>", formik.values.JobDes);
                                    }}
                                  />

                                  {getFormErrorMessage("JobDes")}
                                  {/* Recommended Section Start */}
                                  {/* <RecommendedSection
                    data={recJobDes}
                    field={"JobDes"}
                    promptText={jobDesPrompt}
                    fieldNewValue={jobDesNewValue}
                    onRadioButtonChange={onRadioButtonChange}
                    radioFieldName={"jobDescription"}
                    regenerateBtnClickHandler={regenerateBtnClickHandler}
                    updateBtnHandler={updateBtnHandler}
                    generateJobSectionAI={generateJobSectionAI}
                    setFieldPrompt={setFieldPrompt}
                    clearRecommendArray={clearRecommendArray}
                  /> */}
                                  {/* Recommended Section End */}
                                </div>
                                <div
                                  ref={fieldRefs.rules}
                                  className={`signup-company-information job-textarea-box f-feild ${
                                    isFormFieldValid("rules") && "error-input"
                                  }`}
                                >
                                  <label for="flink">
                                    Roles and responsibilities
                                  </label>
                                  <br />

                                  <ReactQuill
                                    theme="snow"
                                    className={`${isFormFieldValid(
                                      "rules"
                                    )} && "error-input"`}
                                    value={formik?.values?.rules ?? ""}
                                    name="rules"
                                    placeholder="Detail the specific tasks, duties, and responsibilities associated with the role. Be specific about what the job entails."
                                    modules={modules}
                                    formats={formats}
                                    onChange={(data) => {
                                      formik.setFieldValue("rules", data);
                                      formik.handleChange("rules");
                                    }}
                                    onFocus={() => {
                                      if (formik?.values?.rules === "") {
                                        formik.setFieldValue(
                                          "rules",
                                          `<ul><li>${formik?.values?.rules}</li></ul>`
                                        );
                                      }
                                    }}
                                    onBlur={() => {
                                      formik.handleBlur("rules");
                                    }}
                                  />
                                  {getFormErrorMessage("rules")}

                                  {/* Recommended section start */}
                                  {/* <RecommendedSection
                    data={recRules}
                    field={"rules"}
                    promptText={rulesPrompt}
                    fieldNewValue={rulesNewValue}
                    onRadioButtonChange={onRadioButtonChange}
                    radioFieldName={"rules"}
                    regenerateBtnClickHandler={regenerateBtnClickHandler}
                    updateBtnHandler={updateBtnHandler}
                    generateJobSectionAI={generateJobSectionAI}
                    setFieldPrompt={setFieldPrompt}
                    clearRecommendArray={clearRecommendArray}
                  /> */}
                                  {/* Recommended section end */}
                                </div>
                                <div
                                  ref={fieldRefs.skill}
                                  className={`signup-company-information job-textarea-box f-feild ${
                                    isFormFieldValid("skill") && "error-input"
                                  }`}
                                >
                                  <label for="flink">Qualifications</label>
                                  <br />

                                  <ReactQuill
                                    theme="snow"
                                    className={`${isFormFieldValid(
                                      "skill"
                                    )} && "error-input"`}
                                    value={formik?.values?.skill ?? ""}
                                    name="skill"
                                    placeholder="List the required qualifications, including education, certifications, and years of experience. Clearly state what candidates need to meet to be considered for the role."
                                    modules={modules}
                                    formats={formats}
                                    onChange={(data) => {
                                      formik.setFieldValue("skill", data);
                                      formik.handleChange("skill");
                                    }}
                                    onFocus={() => {
                                      if (formik?.values?.skill === "") {
                                        formik.setFieldValue(
                                          "skill",
                                          `<ul><li>${formik?.values?.skill}</li></ul>`
                                        );
                                      }
                                    }}
                                    onBlur={() => {
                                      formik.handleBlur("skill");
                                    }}
                                  />

                                  {getFormErrorMessage("skill")}
                                  {/* Recommended Section Start */}
                                  {/* <RecommendedSection
                    data={recSkills}
                    field={"skill"}
                    promptText={skillsPrompt}
                    fieldNewValue={skillNewValue}
                    onRadioButtonChange={onRadioButtonChange}
                    radioFieldName={"skills"}
                    regenerateBtnClickHandler={regenerateBtnClickHandler}
                    updateBtnHandler={updateBtnHandler}
                    generateJobSectionAI={generateJobSectionAI}
                    setFieldPrompt={setFieldPrompt}
                    clearRecommendArray={clearRecommendArray}
                  /> */}

                                  {/* Recommended Section End */}
                                </div>
                                <div
                                  ref={fieldRefs.mustSkill}
                                  className={`signup-company-information job-textarea-box f-feild ${
                                    isFormFieldValid("mustSkill") &&
                                    "error-input"
                                  }`}
                                >
                                  <label for="flink">Must-have Skills</label>
                                  <br />

                                  <ReactQuill
                                    theme="snow"
                                    className={`${isFormFieldValid(
                                      "mustSkill"
                                    )} && "error-input"`}
                                    value={formik?.values?.mustSkill ?? ""}
                                    name="mustSkill"
                                    placeholder="Provide the essential skills and competencies candidates must possess to excel in this role. Specify what is non-negotiable."
                                    modules={modules}
                                    formats={formats}
                                    onChange={(data) => {
                                      formik.setFieldValue("mustSkill", data);
                                      formik.handleChange("mustSkill");
                                    }}
                                    onFocus={() => {
                                      if (formik?.values?.mustSkill === "") {
                                        formik.setFieldValue(
                                          "mustSkill",
                                          `<ul><li>${formik?.values?.mustSkill}</li></ul>`
                                        );
                                      }
                                    }}
                                    onBlur={() => {
                                      formik.handleBlur("mustSkill");
                                    }}
                                  />
                                  {getFormErrorMessage("mustSkill")}
                                  {/* Recommended Section Start */}
                                  {/* <RecommendedSection
                    data={recMustSkills}
                    field={"mustSkill"}
                    promptText={mustSkillsPrompt}
                    fieldNewValue={mustSkillNewValue}
                    onRadioButtonChange={onRadioButtonChange}
                    radioFieldName={"mustSkills"}
                    regenerateBtnClickHandler={regenerateBtnClickHandler}
                    updateBtnHandler={updateBtnHandler}
                    generateJobSectionAI={generateJobSectionAI}
                    setFieldPrompt={setFieldPrompt}
                    clearRecommendArray={clearRecommendArray}
                  /> */}
                                  {/* Recommended Section End */}
                                </div>
                                <div
                                  ref={fieldRefs.niceToHave}
                                  className={`signup-company-information job-textarea-box f-feild ${
                                    isFormFieldValid("niceToHave") &&
                                    "error-input"
                                  }`}
                                >
                                  <label for="flink">Nice to have</label>
                                  <br />
                                  <ReactQuill
                                    theme="snow"
                                    className={`${isFormFieldValid(
                                      "niceToHave"
                                    )} && "error-input"`}
                                    value={formik?.values?.niceToHave ?? ""}
                                    name="niceToHave"
                                    placeholder="Mention any additional skills or qualifications that would be advantageous but are not mandatory. This can help you find exceptional candidates."
                                    modules={modules}
                                    formats={formats}
                                    onChange={(data) => {
                                      formik.setFieldValue("niceToHave", data);
                                      formik.handleChange("niceToHave");
                                    }}
                                    onFocus={() => {
                                      if (formik?.values?.niceToHave === "") {
                                        formik.setFieldValue(
                                          "niceToHave",
                                          `<ul><li>${formik?.values?.niceToHave}</li></ul>`
                                        );
                                      }
                                    }}
                                    onBlur={() => {
                                      formik.handleBlur("niceToHave");
                                    }}
                                  />
                                  {getFormErrorMessage("niceToHave")}
                                  {/* Recommended Section Start */}
                                  {/* <RecommendedSection
                    data={recNiceToHave}
                    field={"niceToHave"}
                    promptText={niceToHavePrompt}
                    fieldNewValue={niceToHaveNewValue}
                    onRadioButtonChange={onRadioButtonChange}
                    radioFieldName={"niceToHaves"}
                    regenerateBtnClickHandler={regenerateBtnClickHandler}
                    updateBtnHandler={updateBtnHandler}
                    generateJobSectionAI={generateJobSectionAI}
                    setFieldPrompt={setFieldPrompt}
                    clearRecommendArray={clearRecommendArray}
                  /> */}
                                  {/* Recommended Section End */}
                                </div>
                                <div
                                  ref={fieldRefs.benefits}
                                  className={`signup-company-information job-textarea-box f-feild ${
                                    isFormFieldValid("benefits") &&
                                    "error-input"
                                  }`}
                                >
                                  <label for="flink">Benefits</label>
                                  <br />

                                  <ReactQuill
                                    theme="snow"
                                    className={`${isFormFieldValid(
                                      "benefits"
                                    )} && "error-input"`}
                                    value={formik?.values?.benefits ?? ""}
                                    name="benefits"
                                    placeholder="Describe the benefits and perks associated with the position, such as health insurance, retirement plans, flexible hours, or remote work options."
                                    modules={modules}
                                    formats={formats}
                                    onChange={(data) => {
                                      formik.setFieldValue("benefits", data);
                                      formik.handleChange("benefits");
                                    }}
                                    onFocus={() => {
                                      if (formik?.values?.benefits === "") {
                                        formik.setFieldValue(
                                          "benefits",
                                          `<ul><li>${formik?.values?.benefits}</li></ul>`
                                        );
                                      }
                                    }}
                                    onBlur={() => {
                                      formik.handleBlur("benefits");
                                    }}
                                  />

                                  {getFormErrorMessage("benefits")}
                                  {/* Recommended Section Start */}
                                  {/* <RecommendedSection
                    data={recBenefits}
                    field={"benefits"}
                    promptText={benefitsPrompt}
                    fieldNewValue={benefitsNewValue}
                    onRadioButtonChange={onRadioButtonChange}
                    radioFieldName={"benefit"}
                    regenerateBtnClickHandler={regenerateBtnClickHandler}
                    updateBtnHandler={updateBtnHandler}
                    generateJobSectionAI={generateJobSectionAI}
                    setFieldPrompt={setFieldPrompt}
                    clearRecommendArray={clearRecommendArray}
                  /> */}
                                  {/* Recommended Section End */}
                                </div>
                                <div
                                  ref={fieldRefs.workingHours}
                                  className={`signup-company-information job-textarea-box f-feild ${
                                    isFormFieldValid("workingHours") &&
                                    "error-input"
                                  }`}
                                >
                                  <label for="flink">Working hours</label>
                                  <br />

                                  <ReactQuill
                                    theme="snow"
                                    className={`${isFormFieldValid(
                                      "workingHours"
                                    )} && "error-input"`}
                                    value={formik?.values?.workingHours ?? ""}
                                    name="workingHours"
                                    modules={modules}
                                    formats={formats}
                                    placeholder="Working hours such as 9AM - 5PM EST."
                                    onChange={(data) => {
                                      formik.setFieldValue(
                                        "workingHours",
                                        data
                                      );
                                      formik.handleChange("workingHours");
                                    }}
                                    onFocus={() => {
                                      if (formik?.values?.workingHours === "") {
                                        formik.setFieldValue(
                                          "workingHours",
                                          `<ul><li>${formik?.values?.workingHours}</li></ul>`
                                        );
                                      }
                                    }}
                                    onBlur={() => {
                                      formik.handleBlur("workingHours");
                                    }}
                                  />

                                  {getFormErrorMessage("workingHours")}
                                  {/* Recommended Section Start */}
                                  {/* <RecommendedSection
                    data={recWorkingHours}
                    field={"workingHours"}
                    promptText={workingHoursPrompt}
                    fieldNewValue={workingHoursNewValue}
                    onRadioButtonChange={onRadioButtonChange}
                    radioFieldName={"benefit"}
                    regenerateBtnClickHandler={regenerateBtnClickHandler}
                    updateBtnHandler={updateBtnHandler}
                    generateJobSectionAI={generateJobSectionAI}
                    setFieldPrompt={setFieldPrompt}
                    clearRecommendArray={clearRecommendArray}
                  /> */}
                                  {/* Recommended Section End */}
                                </div>
                              </>
                            )}

                            <div
                              className={`signup-company-information job-textarea-box f-feild ${
                                isFormFieldValid("recNotes") && "error-input"
                              }`}
                            >
                              <label for="flink">Recruiter notes</label>
                              <br />

                              <ReactQuill
                                theme="snow"
                                className={`${isFormFieldValid(
                                  "recNotes"
                                )} && "error-input"`}
                                value={formik?.values?.recNotes ?? ""}
                                placeholder="Share any additional information or insights that can help candidates understand the company culture, team dynamics, or unique aspects of the role. This section can provide a more personal touch to the job description."
                                name="recNotes"
                                modules={modules}
                                formats={formatsP}
                                onChange={(data) => {
                                  formik.setFieldValue("recNotes", data);
                                  formik.handleChange("recNotes");
                                }}
                                onBlur={() => {
                                  formik.handleBlur("recNotes");
                                }}
                              />
                              {getFormErrorMessage("recNotes")}
                              {/* Recommended Section Start */}
                              {/* <RecommendedSection
                    data={recRecruiterNotes}
                    field={"recNotes"}
                    promptText={recruiterNotesPrompt}
                    fieldNewValue={recNotesNewValue}
                    onRadioButtonChange={onRadioButtonChange}
                    radioFieldName={"recruiterNotes"}
                    regenerateBtnClickHandler={regenerateBtnClickHandler}
                    updateBtnHandler={updateBtnHandler}
                    generateJobSectionAI={generateJobSectionAI}
                    setFieldPrompt={setFieldPrompt}
                    clearRecommendArray={clearRecommendArray}
                  /> */}
                              {/* Recommended Section End */}
                            </div>

                            <div
                              className={`signup-company-information job-textarea-box f-feild`}
                            >
                              <label for="flink">Interview process</label>
                              <p className="m-0">
                                A great job description helps candidates
                                understand what to expect. Which steps of your
                                interview process would you like to include in
                                the job description to provide transparency?
                              </p>
                              <br />
                              <div className="fd-options mb-3 justify-content-start w-100">
                                {hiringSteps.map((val, idx) => {
                                  return (
                                    <Form.Check
                                      key={val.idx}
                                      inline
                                      label={val.name + " +"}
                                      name="group1"
                                      type="checkbox"
                                      id={`checkbox-${val.id}`}
                                      checked={isItemInHiringArray(val.id)}
                                      onChange={(event) =>
                                        handleCheckboxChange(event, val, val.id)
                                      }
                                    />
                                  );
                                })}
                                <p
                                  className="btn btn-outline-black"
                                  onClick={() => setCustomStage(!customStage)}
                                >
                                  Custom step +
                                </p>
                              </div>

                              <div className="step-wizard">
                                {checked.map((val, i) => {
                                  return (
                                    <div key={i} className="step-wizard-item">
                                      <img
                                        src="/image/check_circle.png"
                                        className="checkCircle"
                                      />
                                      <div className="step-wizard-content">
                                        {val.name}
                                      </div>
                                    </div>
                                  );
                                })}
                              </div>
                            </div>

                            <OutsideClickHandler
                              onOutsideClick={() => {
                                setAllSkillTagsHide(true);
                              }}
                            >
                              <div
                                ref={fieldRefs.skillTags}
                                className={`signup-skill-tags job-textarea-box f-feild tags-tittles`}
                              >
                                <label for="fjobtitles">Tech tags</label>
                                <input
                                  className="form-font-f"
                                  type="text"
                                  name="skillTags"
                                  value={formik.values.skillTags}
                                  onKeyDown={handleSkillTagsKeyDown}
                                  onChange={(e) =>
                                    onSkillTagsChange(e.currentTarget.value)
                                  }
                                  placeholder="Tech tags"
                                />
                                {allSkillTags?.length > 0 && (
                                  <div
                                    className={`job-tags-titles-dropdown ${
                                      allSkillTagsHide && "job-dropdown-hide"
                                    }`}
                                  >
                                    <ul className="dorpdown-list-location">
                                      {allSkillTags.map((j, i) => (
                                        <li                                        
                                          key={i}
                                          onClick={() => {
                                            onSkillTagsClick(j?.name);
                                          }}
                                        >
                                          {" "}
                                          {j?.name}
                                        </li>
                                      ))}
                                    </ul>
                                  </div>
                                )}

                                {selectSkillTags?.length > 0 && (
                                  <ul class="tags-titles-list">
                                    {selectSkillTags.map((j, i) => (
                                      <li key={i}  
                                        className="icon-cursor"
                                      >
                                        {j?.name}
                                        <span
                                          onClick={() => onSkillTagsCancel(i)}
                                        >
                                          <img
                                            src="../image/cross.png"
                                            alt=""
                                          />
                                        </span>
                                      </li>
                                    ))}
                                  </ul>
                                )}
                              </div>
                            </OutsideClickHandler>

                            <div className="signup-salary f-feild">
                              <label for="fsalary">Salary range</label>
                              <br />
                              <div className="salary-range-start-end">
                                <div
                                  className={`merge-feild-1 ${
                                    isFormFieldValid("salaryStart") &&
                                    "error-input"
                                  }`}
                                >
                                  <div
                                    ref={fieldRefs.currency}
                                    className={`salary-f-job ${
                                      isFormFieldValid("currency") &&
                                      "error-input"
                                    }`}
                                  >
                                    <div className="salary-start">
                                      <div
                                        id="fsalary-country"
                                        className="company-form-dropdownBox-f"
                                        data-toggle="dropdown"
                                        aria-haspopup="true"
                                        aria-expanded="false"
                                      >
                                        {formik?.values?.currency !== ""
                                          ? formik?.values?.currency
                                          : "Currency"}
                                      </div>

                                      <div className="list-search-dropdown-country-job list-search-dropdown-country-job-post">
                                        {currencyOption?.length > 0 && (
                                          <ul className="list-job-title-country">
                                            {currencyOption.map((prod) => (
                                              <li
                                                onClick={() =>
                                                  onCurrencyClick(
                                                    prod?.id,
                                                    prod?.title
                                                  )
                                                }
                                              >
                                                <img
                                                  src={
                                                    prod?.flag_link
                                                      ? prod?.flag_link
                                                      : "/image/CA.png"
                                                  }
                                                  alt=""
                                                />{" "}
                                                {`${prod?.title}`}
                                              </li>
                                            ))}
                                          </ul>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                  <div ref={fieldRefs.salaryStart}>
                                    <input
                                      className="form-font-f"
                                      type="text"
                                      name="salaryStart"
                                      id="salary-start-range"
                                      value={formik?.values?.salaryStart}
                                      onChange={formik.handleChange}
                                      onBlur={formik.handleBlur}
                                    />
                                  </div>
                                </div>

                                <div className="merge-feild-2">
                                  <p className="salryto">to</p>
                                </div>
                                <div
                                  ref={fieldRefs.salaryEnd}
                                  className={`merge-feild-3 ${
                                    isFormFieldValid("salaryEnd") &&
                                    "error-input"
                                  }`}
                                >
                                  <input
                                    className="form-font-f"
                                    type="text"
                                    name="salaryEnd"
                                    id="salary-end"
                                    value={formik?.values?.salaryEnd}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                  />
                                </div>
                                <div className="merge-feild-4">
                                  <div
                                    className={`${
                                      isFormFieldValid("salaryRange") &&
                                      "error-input"
                                    }`}
                                  >
                                    <div
                                      className="Year"
                                      ref={fieldRefs.salaryRange}
                                    >
                                      <div
                                        id="fsalary-year"
                                        className="company-form-dropdownBox-f"
                                        data-toggle="dropdown"
                                        aria-haspopup="true"
                                        aria-expanded="false"
                                      >
                                        {formik?.values?.salaryRange !== ""
                                          ? capitalizeFirstLetter(
                                              formik?.values?.salaryRange
                                            )
                                          : "Year"}
                                      </div>

                                      {salaryRangeOption?.length > 0 && (
                                        <div className="list-search-dropdown-year-job">
                                          <ul className="list-job-title-year-job">
                                            {salaryRangeOption.map((prod) => (
                                              <li
                                                onClick={() =>
                                                  onSalaryRangeClick(
                                                    prod?.value
                                                  )
                                                }
                                              >
                                                {" "}
                                                {`${capitalizeFirstLetter(
                                                  prod?.value
                                                )}`}
                                              </li>
                                            ))}
                                          </ul>
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                              {getFormErrorMessage("salaryStart")
                                ? getFormErrorMessage("salaryStart")
                                : getFormErrorMessage("salaryEnd")}
                            </div>

                            <div ref={fieldRefs.jobType}>
                              <CustomSelect
                                id="choose-jobs-type"
                                name="jobType"
                                className="dropdown-icon-postjob form-font-f"
                                label="Job type"
                                label2="Job type"
                                logo="/image/c-z-f.png"
                                borderRed={isFormFieldValid("jobType")}
                                errorMsg={getFormErrorMessage("jobType")}
                                value={formik.values.jobType}
                                data={jobTypeOption}
                                onClickHandler={onJobTypeClick}
                              />
                            </div>
                            <div className={`last-four-feild-post-job`}>
                              {is_Job_Copy_Paste ? (
                                <></>
                              ) : (
                                <>
                                  <CustomSelect
                                    id="choose-jobs"
                                    name="reportingTo"
                                    className="dropdown-icon-postjob form-font-f"
                                    label="Reporting to"
                                    label2="Reporting to"
                                    borderRed={isFormFieldValid("reportingTo")}
                                    errorMsg={getFormErrorMessage(
                                      "reportingTo"
                                    )}
                                    logo={"/image/team-f.png"}
                                    data={reportingToOption}
                                    value={formik?.values?.reportingTo}
                                    onClickHandler={onReportToClick}
                                  />

                                  <CustomSelect
                                    id="choose-jobs"
                                    name="WorkMethodology"
                                    className="dropdown-icon-postjob form-font-f"
                                    label="Work methodology"
                                    label2="Work methodology"
                                    borderRed={isFormFieldValid(
                                      "WorkMethodology"
                                    )}
                                    errorMsg={getFormErrorMessage(
                                      "WorkMethodology"
                                    )}
                                    logo={"/image/w-m-f.png"}
                                    data={WorkMethOption}
                                    value={formik?.values?.WorkMethodology}
                                    onClickHandler={onWorkMethClick}
                                  />
                                </>
                              )}

                              <div
                                class="signup-jobs-option f-feild-post"
                                ref={fieldRefs.state}
                              >
                                <label for="fjobtitles" className="m-0">
                                  Choose state/province
                                </label>
                                <br />
                                <div
                                  className={`profile-field-f signup-passowrd-job location-profile-list  ${
                                    (isFormFieldValid("state") &&
                                      "error-input") ||
                                    (stateOption?.length === 0 &&
                                      "disable-field")
                                  }`}
                                >
                                  <OutsideClickHandler
                                    onOutsideClick={() => {
                                      setStateDDHide(true);
                                    }}
                                  >
                                    <div className="industry-feild-frm bg-pass-f">
                                      <div className="profile-location-feild">
                                        <div
                                          id="dropdown-boxId2"
                                          className="profile-form-dropdownBox1"
                                          onClick={() =>
                                            setStateDDHide(!stateDDHide)
                                          }
                                        >
                                          <div className="text-filter-emp">
                                            <p className="text-filter-select fs-16">
                                              {" "}
                                              {formik?.values?.state === ""
                                                ? "Choose state/province"
                                                : formik?.values?.state}
                                            </p>

                                            <div className="icon-hide">
                                              {isFormFieldValid("state") ? (
                                                <img
                                                  src="/image/warning.png"
                                                  alt=""
                                                />
                                              ) : (
                                                <img
                                                  src="/image/form-d-f.png"
                                                  alt=""
                                                />
                                              )}
                                            </div>
                                          </div>
                                        </div>

                                        <div
                                          className={`admin-status-dropdown-profile ${
                                            !stateDDHide &&
                                            stateOption?.length > 0
                                              ? "open"
                                              : ""
                                          }`}
                                        >
                                          <div className="searchbox-invite">
                                            <label
                                              for="job-search-field-8"
                                              className="visibility-hidden opacity-0 position-absolute"
                                            >
                                              switch
                                            </label>
                                            <input
                                              name="searchLocation"
                                              value={stateSearch}
                                              onChange={(e) =>
                                                searchStateLocation(e)
                                              }
                                              id="job-search-field-8"
                                              className="location-search-field"
                                              placeholder="Province/State"
                                              autocomplete="off"
                                            />
                                          </div>

                                          {stateOption?.length > 0 &&
                                            !filteredStateItem.length && (
                                              <>
                                                <ul className="dorpdown-list-location">
                                                  {stateOption?.map((j, i) => (
                                                    <li
                                                      className="icon-cursor"
                                                      onClick={() =>
                                                        onStateClick(
                                                          j?.id,
                                                          j?.name
                                                        )
                                                      }
                                                    >
                                                      <img
                                                        src="/image/location-j-place.png"
                                                        alt=""
                                                      />
                                                      {j?.name}
                                                    </li>
                                                  ))}
                                                </ul>
                                              </>
                                            )}

                                          {filteredStateItem?.length > 0 && (
                                            <>
                                              <ul className="dorpdown-list-location">
                                                {filteredStateItem.map(
                                                  (j, i) => (
                                                    <li
                                                      className="icon-cursor"
                                                      onClick={() =>
                                                        onStateClick(
                                                          j?.id,
                                                          j?.name
                                                        )
                                                      }
                                                    >
                                                      <img
                                                        src="/image/location-j-place.png"
                                                        alt=""
                                                      />
                                                      {j?.name}
                                                    </li>
                                                  )
                                                )}
                                              </ul>
                                            </>
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                    {getFormErrorMessage("state")}
                                  </OutsideClickHandler>
                                </div>
                              </div>
                              <div
                                class="signup-jobs-option f-feild-post"
                                ref={fieldRefs.city}
                              >
                                <label for="fjobtitles" className="m-0">
                                  Choose City
                                </label>
                                <br />
                                <div
                                  className={`profile-field-f signup-passowrd-job location-profile-list ${
                                    (isFormFieldValid("city") &&
                                      "error-input") ||
                                    (cityOption?.length === 0 &&
                                      "disable-field")
                                  }`}
                                >
                                  <OutsideClickHandler
                                    onOutsideClick={() => {
                                      setCityDDHide(true);
                                    }}
                                  >
                                    <div className="industry-feild-frm bg-pass-f">
                                      <div className="profile-location-feild">
                                        <div
                                          id="dropdown-boxId2"
                                          className="profile-form-dropdownBox1"
                                          onClick={() =>
                                            setCityDDHide(!cityDDHide)
                                          }
                                        >
                                          <div className="text-filter-emp">
                                            <p className="text-filter-select fs-16">
                                              {" "}
                                              {formik?.values?.city === ""
                                                ? "Choose City"
                                                : formik?.values?.city}
                                            </p>

                                            <div className="icon-hide">
                                              {isFormFieldValid("city") ? (
                                                <img
                                                  src="/image/warning.png"
                                                  alt=""
                                                />
                                              ) : (
                                                <img
                                                  src="/image/form-d-f.png"
                                                  alt=""
                                                />
                                              )}
                                            </div>
                                          </div>
                                        </div>
                                        <div
                                          className={`admin-status-dropdown-profile ${
                                            !cityDDHide &&
                                            cityOption?.length > 0
                                              ? "open"
                                              : ""
                                          }`}
                                        >
                                          <div className="searchbox-invite">
                                            <label
                                              for="job-search-field-9"
                                              className="visibility-hidden opacity-0 position-absolute"
                                            >
                                              switch
                                            </label>
                                            <input
                                              name="searchLocation"
                                              value={citySearch}
                                              onChange={(e) =>
                                                searchCityLocation(e)
                                              }
                                              id="job-search-field-9"
                                              className="location-search-field"
                                              placeholder="City"
                                              autocomplete="off"
                                            />
                                          </div>

                                          {cityOption?.length > 0 &&
                                            !filteredCityItem.length && (
                                              <>
                                                <ul className="dorpdown-list-location">
                                                  {cityOption?.map((j, i) => (
                                                    <li
                                                      className="icon-cursor"
                                                      onClick={() =>
                                                        onCitiesClick(
                                                          j?.id,
                                                          j?.name
                                                        )
                                                      }
                                                    >
                                                      <img
                                                        src="/image/location-j-place.png"
                                                        alt=""
                                                      />
                                                      {j?.name}
                                                    </li>
                                                  ))}
                                                </ul>
                                              </>
                                            )}

                                          {filteredCityItem?.length > 0 && (
                                            <>
                                              <ul className="dorpdown-list-location">
                                                {filteredCityItem.map(
                                                  (j, i) => (
                                                    <li
                                                      className="icon-cursor"
                                                      onClick={() =>
                                                        onCitiesClick(
                                                          j?.id,
                                                          j?.name
                                                        )
                                                      }
                                                    >
                                                      <img
                                                        src="/image/location-j-place.png"
                                                        alt=""
                                                      />
                                                      {j?.name}
                                                    </li>
                                                  )
                                                )}
                                              </ul>
                                            </>
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                    {getFormErrorMessage("city")}
                                  </OutsideClickHandler>
                                </div>
                              </div>
                            </div>
                          </>
                        ) : null}
                      </form>
                    </div>
                  </div>
                </div>
                {mode === "edit" ||
                jobDecision === JOB_POST_DECISION.COPY_PASTE ||
                showFields === true ? (
                  <>
                    <div className="candidate-from-signup-create-final-job btn-next-pre">
                      {mode === "edit" ? (
                        <>
                          <button
                            className="btn-next-previous btn-next-pre-toggle"
                            onClick={async () => {
                              navigate("/employer-dashboard");
                            }}
                          >
                            Cancel
                          </button>

                          <button
                            className="btn-job-post"
                            onClick={async () => {
                              if (postJobEditForm === "draft") {
                                await formik.setFieldValue("status", "draft");
                                onPostNowClick();
                              } else {
                                if (jobDecision === JOB_POST_DECISION.ZOE) {
                                  await formik.setFieldValue(
                                    "status",
                                    "published"
                                  );
                                  onPostNowClick();
                                }

                                const postNow = async () => {
                                  await formik.setFieldValue(
                                    "status",
                                    "published"
                                  );
                                  onPostNowClick();
                                };

                                setHandleNoButtonPostNow(() => postNow);

                                if (
                                  jobDecision === JOB_POST_DECISION.COPY_PASTE
                                ) {
                                  await formik.setFieldValue(
                                    "status",
                                    "published"
                                  );
                                  onPostNowClick();
                                  //dispatch(openZoeModal());
                                  //setShowPromptMessageField(false);
                                  //return;
                                }

                                if (
                                  jobDecision === JOB_POST_DECISION.SYSTEM_GEN
                                ) {
                                  await formik.setFieldValue(
                                    "status",
                                    "published"
                                  );
                                  onPostNowClick();
                                }
                              }
                            }}
                          >
                            {loader && formik?.values?.status === "draft" ? (
                              <span
                                className="spinner-border spinner-border-sm"
                                role="status"
                                aria-hidden="true"
                              />
                            ) : (
                              " Update"
                            )}
                          </button>
                        </>
                      ) : (
                        <>
                          <button
                            className="btn-next-previous btn-next-pre-toggle"
                            onClick={async () => {
                              await formik.setFieldValue("status", "draft");
                              onPostNowClick();
                            }}
                          >
                            {loader && formik?.values?.status === "draft" ? (
                              <span
                                className="spinner-border spinner-border-sm"
                                role="status"
                                aria-hidden="true"
                              />
                            ) : (
                              " Save as draft"
                            )}
                          </button>
                          <button
                            className="btn-job-post"
                            type="submit"
                            onClick={async () => {
                              if (jobDecision === JOB_POST_DECISION.ZOE) {
                                await formik.setFieldValue(
                                  "status",
                                  "published"
                                );
                                onPostNowClick();
                              }

                              const postNow = async () => {
                                await formik.setFieldValue(
                                  "status",
                                  "published"
                                );
                                onPostNowClick();
                              };

                              setHandleNoButtonPostNow(() => postNow);

                              if (
                                jobDecision === JOB_POST_DECISION.COPY_PASTE
                              ) {
                                await formik.setFieldValue(
                                  "status",
                                  "published"
                                );
                                onPostNowClick();
                                //dispatch(openZoeModal());
                                //setShowPromptMessageField(false);
                                //return;
                              }

                              if (
                                jobDecision === JOB_POST_DECISION.SYSTEM_GEN
                              ) {
                                await formik.setFieldValue(
                                  "status",
                                  "published"
                                );
                                onPostNowClick();
                              }
                            }}
                          >
                            {loader &&
                            formik?.values?.status === "published" ? (
                              <span
                                className="spinner-border spinner-border-sm"
                                role="status"
                                aria-hidden="true"
                              />
                            ) : (
                              "Post now"
                            )}
                          </button>
                        </>
                      )}
                    </div>
                  </>
                ) : null}
              </div>
            </>
          )}
        </>
      )}
      {customStage && (
        <ContentModal closeModal={() => setCustomStage(!customStage)}>
          <div>
            <div className="modal-body">
              <h3 className="mt-0"> Add custom step</h3>
              <div className="crea-list-m text-start w-100">
                <div className="label-field">
                  <label for="">Step name </label>
                  <div className="share-list-feild">
                    <div
                      id="dropdown-boxId2"
                      className="company-form-dropdownBox1 custom-tag-input"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <div className="text-filter-admin">
                        <input
                          type="text "
                          className="form-font-list-share"
                          name="campanyUser"
                          autoComplete="off"
                          onChange={(e) => {
                            setNewStagesValue(e.target.value);
                          }}
                          placeholder="Step name"
                        />

                        <img
                          src="/image/admin-d.png"
                          alt=""
                          className="hide-tick"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                className="btn btn-black"
                onClick={() => setCustomStage(!customStage)}
              >
                Cancel
              </button>
              <button className="btn btn-outline-black" onClick={saveNewStage}>
                Edit & save
              </button>
            </div>
          </div>
        </ContentModal>
      )}
    </>
  );
};

export default PostJobForm;
