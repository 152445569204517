import React, { useEffect, useState } from "react";
import "./HelpYouTube.css";
import YouTube from "react-youtube";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Card from "../../components/Common/VideoCard/Card";
import WorldConnect from "../../components/Common/WorldConnect/WorldConnect";

const HelpYouTube = () => {
  const [videos, setVideos] = useState([]);
  const [showVideos, setShowVideos] = useState([]);
  const [tagType, setTagType] = useState('Employer');
  const [selectedVideo, setSelectedVideo] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [openSearch, setOpenSearch] = useState(false);
  const navigate = useNavigate();
  const apiKey ='AIzaSyBx6tUXVCK0uNm7z6hLpU_BO3m-gq_c8AQ'
  const chanelId = 'UC4sbRmmsivjajOnHnkGdLFA'

  const fetchVideos = async () => {
    try {
      // Step 1: Fetch video IDs from the search endpoint
      const searchResponse = await fetch(
        `https://www.googleapis.com/youtube/v3/search?key=${apiKey}&channelId=${chanelId}&part=snippet&maxResults=50`
      );
      const searchData = await searchResponse.json();

      if (!searchData.items) {
        setError("No videos found.");
        return;
      }

      // Extract video IDs from the search results
      const videoIds = searchData.items
        .map((item) => item.id.videoId)
        .join(",");

      // Step 2: Fetch video details (including statistics and snippet) using the videos endpoint
      const videosResponse = await fetch(
        `https://www.googleapis.com/youtube/v3/videos?key=${apiKey}&id=${videoIds}&part=snippet,statistics`
      );
      const videosData = await videosResponse.json();

      if (videosData.items) {
        setVideos(videosData.items);
        sortVideos(tagType, videosData.items)
        // Map the video details to include view count and time since upload
        
      } else {
        setError("No video details found.");
      }
    } catch (err) {
      setError("Error fetching videos.");
    } finally {
      setLoading(false);
    }
  };

  const sortVideos = (type = 'Employer', videos=videos ) => {
    setTagType(type)
    const filteredVideos = videos.filter(video =>
      video.snippet.tags && video.snippet.tags.includes(type)
    );
    console.log(filteredVideos, type, 'im ni')
    const videosWithDetails = filteredVideos.map((video) => ({
      id: video.id,
      snippet: video.snippet,
      statistics: video.statistics,
      uploadTime: getTimeSinceUpload(video.snippet.publishedAt), // Calculate time since upload
    }));
    setShowVideos(videosWithDetails);
    
  }

  useEffect(() => {
    fetchVideos();
  }, []);

  // Helper function to calculate time since upload
  const getTimeSinceUpload = (publishedAt) => {
    const uploadDate = new Date(publishedAt);
    const currentDate = new Date();
    const timeDifference = currentDate - uploadDate;

    // Convert time difference to seconds, minutes, hours, days, etc.
    const seconds = Math.floor(timeDifference / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);
    const weeks = Math.floor(days / 7);

    if (weeks > 0) {
      return `${weeks} week${weeks > 1 ? "s" : ""} ago`;
    } else if (days > 0) {
      return `${days} day${days > 1 ? "s" : ""} ago`;
    } else if (hours > 0) {
      return `${hours} hour${hours > 1 ? "s" : ""} ago`;
    } else if (minutes > 0) {
      return `${minutes} minute${minutes > 1 ? "s" : ""} ago`;
    } else {
      return `${seconds} second${seconds > 1 ? "s" : ""} ago`;
    }
  };

  return (
    <>
      <div className="container container-site py-2">
        <Button
          variant="link"
          className="back-to-home add top-0 position-relative"
          onClick={() => navigate(-1)}
        >
          <span className="visibility-hidden opacity-0 position-absolute">
            Go back
          </span>
          <img src="/image/form-back-arrow.png" alt="" />
        </Button>
      </div>
      <section className="job-heading">
        <div className="container container-site">
          <div className="job-sec-wrape">
            <div
              className={`job-heading-home job-heading-local w-100 ${
                openSearch ? "job-heading-local-mbl" : ""
              }`}
            >
              <h2>Resources</h2>
              <div
                className={`job-search-div m-0 ${
                  openSearch ? "job-searchBtn-open" : "job-search-div-mbl"
                }`}
                onClick={() => {
                  setOpenSearch(true);
                }}
              >
                <img
                  className="job-searchIcon job-searchIcon-mbl"
                  src="/image/job-searchIcon.png"
                  alt="job-searchBar"
                />
              </div>
              <div
                className={`job-search-div m-0 ${
                  openSearch ? "job-search-div-abs" : "job-search-open"
                }`}
              >
                <img
                  className="job-searchIcon"
                  src="/image/job-searchIcon.png"
                  alt="job-searchBar"
                  onClick={() => {
                    setOpenSearch(false);
                  }}
                />
                <label
                  for="input-search"
                  className="visibility-hidden opacity-0 position-absolute"
                >
                  {" "}
                  Search jobs
                </label>
                <input
                  placeholder="Search jobs"
                  className="job-search-input"
                  value=""
                  id="input-search"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="container container-site">
        <div className="mt-4 d-flex justify-content-between">
          <h3 className="fs-[28px] fw-700">Videos</h3>
          <div className="d-flex gap-2">
            <button className="btn btn-outline-black">Blogs</button>
            <button className="btn btn-black">Videos</button>
            <button className="btn btn-outline-black">Webinar</button>
          </div>
        </div>
        <p className="mt-3 mb-3">
          Lorem Ipsum is simply dummy text of the printing and typesetting
          industry. Lorem Ipsum has been the industry's standard dummy text ever
          since the 1500s, when an unknown printer took a galley of type and
          scrambled it to make a type specimen book.{" "}
        </p>
        <div className="d-flex gap-2">
          {/* {tagType} */}
          <button className={`btn ${tagType === 'Candidate' ? 'btn-black' : 'btn-outline-black'}`} onClick={() => sortVideos('Candidate', videos)}>Candidate</button>
          <button className={`btn ${tagType === 'Employer' ? 'btn-black' : 'btn-outline-black'}`} onClick={() => sortVideos('Employer', videos)}>Employer</button>
        </div>
        <div>
          {loading && <p>Loading...</p>}
          {error && <p className="error">{error}</p>}
          <div className="mt-4 video-grid mb-5">
            {/* {showVideos.length} */}
            {showVideos.map((v) => {
                return (
                  <Card
                    key={v.id.videoId} // Ensure to add a key for list rendering
                    videoId={v.id.videoId}
                    title={v.snippet.title}
                    thumbnails={v.snippet.thumbnails.high.url}
                    views={v.statistics.viewCount}
                    uploadTime={v.uploadTime}
                    onClick={() => setSelectedVideo(v.id)} // Set selected video on click
                  />
                );
              })}
          </div>
          {selectedVideo && (
            <div className="video-overlay">
              <div className="overlay-content">
                <YouTube
                  videoId={selectedVideo}
                  className="video-player"
                  opts={{
                    width: "100%",
                    height: "100%",
                    playerVars: {
                      autoplay: 1,
                      controls: 1,
                    },
                  }}
                />
                <button
                  className="close-btn"
                  onClick={() => setSelectedVideo(null)}
                >
                  ✖
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="yt-banner">
        <div className="container container-site">
          <div className="row align-items-center">
            <div className="col-md-6">
              <div className="yt-content">
                <img className="vc-img img-top" src='./image/yc-top.png' alt='yc-top' />
                <img className="vc-img img-bottom" src='./image/yc-bottom.png' alt='yc-bottom' />
                <h4 className="demo-page-home-title">Want a professional <span className="text-primary">ATS Friendly</span> <br/> Resume?</h4>
                <ul className="my-3 ps-3 opacity-50">
                  <li>Why We are Most Popular</li>  
                  <li>Why We are Most Popular</li>  
                  <li>Why We are Most Popular</li>  
                </ul>  
                <div className="mt-4 d-flex gap-3">
                  <button className="btn btn-outline-black bg-white d-flex justify-content-center align-items-center">
                    Watch video
                    <img src='./image/yt-play.png' alt='yt-play' className="w-25" />
                  </button>
                  <button className="btn btn-black d-flex justify-content-center align-items-center gap-2">
                    Create new 
                  <img src='./image/yt-new.png' alt='yt-play' className="h-50" />
                  </button>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <img src='./image/yt-right.png' alt='yt-right' className="img-fluid" />
            </div>
          </div>
        </div>
      </div>
      <WorldConnect/>
    </>
  );
};

export default HelpYouTube;
