import { useState, useEffect } from "react";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import { DateRangePicker } from "react-date-range";
import { addMonths, addDays, format } from "date-fns";
import { enUS } from "date-fns/locale";
import {
  getPromotionJobsAsync,
  getCreatePromotionPagesForAdsAsync,
  setPromotionFormMode,
  setSelectedPromotionType,
  setSelectedTimeFrame,
  setSelectedPromotionDate,
  setSelectedJob,
  setSelectedPage,
} from "../../../../redux/slices/companyPromotionSlice";
import { FormDropdown } from "../../../../components/Admin/Promotion/CreatePromotion/FormDropdown";
import { promotionTypes, timeFrames } from "../../../../constants";

const AdminCreateProJobForm = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  /*Dropdown Value*/
  const [promotionJobs, setPromotionJobs] = useState([]);
  const [promotionJobsLoading, setPromotionJobsLoading] = useState(false);
  const [promotionPages, setPromotionPages] = useState([]);
  const [promotionPagesLoading, setPromotionPagesLoading] = useState(false);

  /* Date Picker */
  const [selectionRange, setSelectionRange] = useState({
    startDate: new Date(),
    endDate: addDays(new Date(), 6),
    key: "selection",
  });

  const formik = useFormik({
    initialValues: {
      job: "",
      jobId: null,
      page: "",
      pageId: null,
    },
    /*validationscheme*/
    validationSchema: Yup.object({
      promotionDate: Yup.string(),
      job: Yup.string().required("Please select a job to promote"),
      page: Yup.string().required("Please select a page to promote"),
    }),
    onSubmit: async (data, { resetForm }) => {
      dispatch(setPromotionFormMode("add"));
      navigate("/admin-promotion-slot");
    },
  });

  const dateRangeSelectHandler = ({ selection }) => {
    const { startDate } = selection;

    const calculatedEndDate = addDays(startDate, 6);
    const formattedStartDate = format(startDate, "yyyy-MM-dd");
    const formattedEndDate = format(calculatedEndDate, "yyyy-MM-dd");

    setSelectionRange({
      startDate,
      endDate: calculatedEndDate,
      key: "selection",
    });

    dispatch(
      setSelectedPromotionDate({
        start_date_D: startDate,
        end_date_D: calculatedEndDate,
        start_date: formattedStartDate,
        end_date: formattedEndDate,
      })
    );

    getPromotionJobs({
      start_date: formattedStartDate,
      end_date: formattedEndDate,
    });
  };

  const hasNoJobsForSelectedPromotionDate = promotionJobs.length === 0;

  const onJobSelect = async (id, name, item) => {
    if (!hasNoJobsForSelectedPromotionDate) {
      await formik.setFieldValue("jobId", id);
      await formik.setFieldValue("job", item?.full_title);
      dispatch(setSelectedJob({ id: id, name: item?.full_title }));
    }
  };

  const onJobPageSelection = async (id, name, item) => {
    await formik.setFieldValue("page", name);
    await formik.setFieldValue("pageId", id);
    dispatch(
      setSelectedPage({ id: id, name: name, section: item.sections[0] })
    );
  };

  const getPromotionJobs = async ({ start_date, end_date }) => {
    setPromotionJobsLoading(true);
    dispatch(getPromotionJobsAsync({ start_date, end_date }))
      .then((response) => {
        const res = response?.payload;
        if (res?.success === true) {
          setPromotionJobs(res?.data);
        }
        setPromotionJobsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setPromotionJobsLoading(false);
      });
  };

  const getPromotionPagesData = async () => {
    setPromotionPagesLoading(true);
    dispatch(getCreatePromotionPagesForAdsAsync())
      .then((response) => {
        const res = response?.payload;
        if (res?.success === true) {
          setPromotionPages(res?.data);
        }
        setPromotionPagesLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setPromotionPagesLoading(false);
      });
  };

  const isFormFieldValid = (name) =>
    !!(formik.touched[name] && formik.errors[name]);
  const getFormErrorMessage = (name) => {
    return (
      isFormFieldValid(name) && (
        <p className="error-msg">{formik.errors[name]}</p>
      )
    );
  };

  useEffect(() => {
    dispatch(
      setSelectedPromotionDate({
        start_date_D: selectionRange.startDate,
        end_date_D: selectionRange.endDate,
        start_date: format(selectionRange.startDate, "yyyy-MM-dd"),
        end_date: format(selectionRange.endDate, "yyyy-MM-dd"),
      })
    );
    getPromotionJobs({
      start_date: format(selectionRange.startDate, "yyyy-MM-dd"),
      end_date: format(selectionRange.endDate, "yyyy-MM-dd"),
    });
  }, [selectionRange]);

  useEffect(() => {
    async function getSelectedProValue() {
      dispatch(
        setSelectedPromotionType({
          id: promotionTypes[1].id,
          name: promotionTypes[1].name,
        })
      );
      dispatch(
        setSelectedTimeFrame({ id: timeFrames[1].id, name: timeFrames[1].name })
      );
      getPromotionPagesData(timeFrames[1].id);
    }
    getSelectedProValue();
  }, []);

  return (
    <div className="department-role-space bg-clr-admin">
      <div className="form-heading-prom">
        <h2>Promote your job post</h2>
      </div>
      <div className="form-text-start">
        You can promote any of your existing job posts for just $99 weekly
        (taxes extra). Select the timeframe, date, the job post, and hit 'Next.'
      </div>
      <div className="department-form-box">
        <div className="form-labeal-heading-admin">
          <h2>Promote your job</h2>
        </div>
        <form onSubmit={formik.handleSubmit}>
          <div className="admin-from-step-pro make-dd-100">
            <div>
              <label>Select date *</label>
              <div className="vertial-line pr-20">
                <DateRangePicker
                  ranges={[selectionRange]}
                  onChange={dateRangeSelectHandler}
                  months={2}
                  direction="horizontal"
                  editableDateInputs={false}
                  staticRanges={[]}
                  inputRanges={[]}
                  locale={enUS}
                  minDate={new Date()}
                  maxDate={addMonths(new Date(), 2)}
                  preventSnapRefocus={true}
                />
              </div>            
            </div>
            <div className="dd-container-pro">
              <FormDropdown
                label="Select job to promote"
                placeholder="Job title"
                required
                value={formik?.values?.job}
                loading={promotionJobsLoading}
                isInvalid={isFormFieldValid("job")}
                error={getFormErrorMessage("job")}
                options={
                  hasNoJobsForSelectedPromotionDate
                    ? [{ id: 0, name: "No jobs for selected date" }]
                    : promotionJobs
                }
                onSelect={onJobSelect}
                formik={formik}
              />
              <br />
              <FormDropdown
                label="Select location"
                placeholder="Location"
                required
                value={formik?.values?.page}
                loading={promotionPagesLoading}
                isInvalid={isFormFieldValid("page")}
                error={getFormErrorMessage("page")}
                options={promotionPages}
                onSelect={onJobPageSelection}
                formik={formik}
              />
            </div>
          </div>
          <div className="img-btn-adminform">
            {/* <div className="admin-frm-avtar">
              <img src="/image/pormotion-form-img.png" alt="" />
            </div> */}
            <div className="admin-form-btn">
              <button
                className="cancel-admin"
                onClick={() => navigate("/admin-all-promotion")}
              >
                Cancel
              </button>
              <button className="next-admin" type="submit">
                Next
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AdminCreateProJobForm;
