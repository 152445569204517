import { useState, useEffect } from "react";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import { DateRangePicker } from "react-date-range";
import { addMonths, addDays, format } from "date-fns";
import { enUS } from "date-fns/locale";
import {
  setPromotionFormMode,
  setSelectedPromotionType,
  setSelectedTimeFrame,
  setSelectedPromotionDate,
} from "../../../../redux/slices/companyPromotionSlice";
import { promotionTypes, timeFrames } from "../../../../constants";

const AdminCreateProCompanyForm = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  /* Date Picker */
  const [selectionRange, setSelectionRange] = useState({
    startDate: new Date(),
    endDate: addDays(new Date(), 29),
    key: "selection",
  });

  const formik = useFormik({
    initialValues: {},
    /*validationscheme*/
    validationSchema: Yup.object({}),
    onSubmit: async (data, { resetForm }) => {
      dispatch(setPromotionFormMode("add"));
      navigate("/admin-promotion-slot");
    },
  });

  const dateRangeSelectHandler = ({ selection }) => {
    const { startDate } = selection;
    const calculatedEndDate = addDays(startDate, 29);

    setSelectionRange({
      startDate,
      endDate: calculatedEndDate,
      key: "selection",
    });

    dispatch(
      setSelectedPromotionDate({
        start_date_D: startDate,
        end_date_D: calculatedEndDate,
        start_date: format(startDate, "yyyy-MM-dd"),
        end_date: format(calculatedEndDate, "yyyy-MM-dd"),
      })
    );
  };

  useEffect(() => {
    dispatch(
      setSelectedPromotionDate({
        start_date_D: selectionRange.startDate,
        end_date_D: selectionRange.endDate,
        start_date: format(selectionRange.startDate, "yyyy-MM-dd"),
        end_date: format(selectionRange.endDate, "yyyy-MM-dd"),
      })
    );
  }, [selectionRange]);

  useEffect(() => {
    async function getSelectedProValue() {
      dispatch(
        setSelectedPromotionType({
          id: promotionTypes[0].id,
          name: promotionTypes[0].name,
        })
      );
      dispatch(
        setSelectedTimeFrame({ id: timeFrames[0].id, name: timeFrames[0].name })
      );
    }
    getSelectedProValue();
  }, []);

  return (
    <div className="department-role-space bg-clr-admin">
      <div className="form-heading-prom">
        <h2>Promote company</h2>
      </div>
      <div className="form-text-start">
        Want to boost your company’s visibility and jobs? You can promote all
        the jobs you’ve purchased on our front page for just $500 extra for 4
        weeks.
      </div>
      <div className="department-form-box">
        <div className="form-labeal-heading-admin">
          <h2>Promote your Company</h2>
        </div>
        <form onSubmit={formik.handleSubmit} className="make-dd-100">
          <div className="admin-from-step-pro">
            <div>
              <label>Select date *</label>
              <div>
                <DateRangePicker
                  ranges={[selectionRange]}
                  onChange={dateRangeSelectHandler}
                  months={2}
                  direction="horizontal"
                  editableDateInputs={false}
                  staticRanges={[]}
                  inputRanges={[]}
                  locale={enUS}
                  minDate={new Date()}
                  maxDate={addMonths(new Date(), 2)}
                  preventSnapRefocus={true}
                />
              </div>
            </div>
          </div>
          <div className="img-btn-adminform">
            {/* <div className="admin-frm-avtar">
              <img src="/image/pormotion-form-img.png" alt="" />
            </div> */}
            <div className="admin-form-btn">
              <button
                className="cancel-admin"
                onClick={() => navigate("/admin-all-promotion")}
              >
                Cancel
              </button>
              <button className="next-admin" type="submit">
                Next
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AdminCreateProCompanyForm;
