import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import OutsideClickHandler from "react-outside-click-handler";
import { setSelectJobId } from "../../../redux/slices/JobDetailSlice";
import {
  setActiveTab,
  setPostJobId,
  setPostJobMode,
} from "../../../redux/slices/AuthSlice";
import { setCandidateListId, setPostJobEditForm } from "../../../redux/slices/employerDashboardSlice";
import { setCandidateListingDropdown } from "../../../redux/slices/candidateListingSlice";
import getDays from "../../../utils/getDays";
import getDaysUntilExpiration from "../../../utils/getDaysUntilExpiration";
import { EMP_CAND_LIST } from "../../../constants";
import "./JobRecord.css";

const JobListingRight = ({
  hashId,
  slug,
  selectedJobId,
  isActive,
  date,
  expireDate,
  myLists,
  listSharedWithMe,
  jobDetailData,
  addMemberinJob
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { candidateDetailTab, candidateDetailId, candidateDetailResumeId, jobDetailId } = useSelector(
    (state) => state.candidateDetail
  );
  const { user } = useSelector((state) => state.auth);

  /*Candidate Listing Menu*/
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [activeSubMenu, setActiveSubMenu] = useState(null);
  const [isSharedMenuOpen, setIsSharedMenuOpen] = useState(false);
  const [activeSharedSubMenu, setActiveSharedSubMenu] = useState(null);
  const [showMenu, setShowMenu] = useState(false);

  const addMemberToJob = (jodId) => {
    addMemberinJob(jodId)
  }

  /*Candidate Listing Menu Event */
  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
    setIsSharedMenuOpen(false);
  };

  const toggleSubMenu = (index) => {
    setActiveSubMenu(activeSubMenu === index ? null : index);
  };

  const toggleSharedMenu = () => {
    setIsSharedMenuOpen(!isSharedMenuOpen);
    setIsMenuOpen(false);
  };

  const toggleSharedSubMenu = (index) => {
    setActiveSharedSubMenu(activeSharedSubMenu === index ? null : index);
  };

  const onDetailJobHandler = () => {
    if (slug && hashId) {
      navigate(`/jobs/${slug}/?currentJobId=${hashId}`);
    }
  };

  const editJobClickHanlder = (id) => {
    dispatch(setActiveTab("employer"));
    dispatch(setPostJobMode("edit"));
    dispatch(setPostJobEditForm("listing"));
    dispatch(setPostJobId(id));
    navigate("/post-job?edit=true");
  };

  const onCandidateListHandler = (id, name, value) => {
    dispatch(setCandidateListId(hashId));
    dispatch(
      setCandidateListingDropdown({
        id,
        name,
        value,
      })
    );
    navigate(`/employer-candidate-list`);
  };

  return (
    <div className="job-announcements">
      <div className="job-announcements-col btn-size dash-bord-list">
        <div className="ellipses-dd text-right d-md-block d-none">
          <button className="btn btn-transparent p-0" onClick={() => setShowMenu(!showMenu)}>
            <img src="/image/adJob-dd.png" alt="adJob-dd" />
          </button>
          <div className={showMenu ? 'cv-cions active' : 'cv-cions'}>
            <div className="dd-val" onClick={() =>  navigate("/admin-promotion-job")}>
              <img src="/image/promote-job.png" alt="" className="show-tooltip" />
              <p className="dd-content">Promote job</p>
            </div>
            <div className="dd-val" onClick={() => editJobClickHanlder(selectedJobId)}>
              <img src="/image/edit-black.png" alt="" className="show-tooltip" />
              <p className="dd-content">Edit</p>
            </div>
          </div>
        </div>
        {/* <div class="edit-icon-emply">        
          <img
            src="/image/edit-dash.png"
            alt=""
            onClick={() => editJobClickHanlder(selectedJobId)}
          />         
        </div> */}
        <div className="tow-btn-job-dash">
          <a onClick={() => onDetailJobHandler()}>
            <button className="w-100">View job</button>
          </a>
          <OutsideClickHandler
            onOutsideClick={() => {
              setIsMenuOpen(false);
              setActiveSubMenu(null);
              setIsSharedMenuOpen(false);
              setActiveSharedSubMenu(null);
            }}
          >
            <div className="multi-dropdown-box bg-transparent mb-2">
              <ul className="first-menu-dropdown">
                <li
                  className={`sub-menu-dropdpwn main-dropdown-heading w-100 ${isMenuOpen ? "active" : ""
                    }`}
                >
                  <Link
                    className="sub-menu-lists clr-set"
                    aria-current="page"
                    onClick={toggleMenu}
                  >
                    <img
                      className="view-candidate-img"
                      src="/image/candidate-job-list.png"
                      alt=""
                    />{" "}
                    View candidates
                    <img
                      className="view-candidate-arrow-down"
                      src="/image/v-blue.png"
                      alt=""
                    />
                  </Link>

                  <ul
                    className={`second-menu-dropdown second-menu-dropdown-sroll ${isMenuOpen ? "d-block" : ""
                      }`}
                  >
                    <li
                      className="sub-menu-dropdpwn sub-menu-dropdpwn-list-f"
                      onClick={() =>
                        onCandidateListHandler(null, "", EMP_CAND_LIST.ALL)
                      }
                    >
                      <Link className="sub-menu-lists">
                        All candidates
                        <img src="" width="" height="" className="" alt="" />
                      </Link>
                    </li>

                    {myLists?.length > 0 && (
                      <li
                        className={`sub-menu-dropdpwn ${activeSubMenu === 1 ? "active" : ""
                          }`}
                      >
                        <Link
                          className="sub-menu-lists"
                          onClick={() => toggleSubMenu(1)}
                        >
                          Lists created by me
                          <img
                            src="/image/cand-vactor.png"
                            className=""
                            alt=""
                          />
                        </Link>
                        <ul
                          className={`second-menu-dropdown second-menu-dropdown-border ${activeSubMenu === 1 ? "d-block" : ""
                            }`}
                        >
                          {myLists?.map((j, i) => (
                            <li
                              className="sub-menu-dropdpwn"
                              onClick={() =>
                                onCandidateListHandler(
                                  j?.id,
                                  j?.name,
                                  EMP_CAND_LIST.MY_LIST
                                )
                              }
                            >
                              <Link className="sub-menu-lists">
                                {j?.name}
                                <img
                                  src="/image/table-list.png"
                                  className="page-copy-img"
                                  alt=""
                                />
                              </Link>
                            </li>
                          ))}
                        </ul>
                      </li>
                    )}
                    {listSharedWithMe?.length > 0 && (
                      <li
                        className={`sub-menu-dropdpwn ${activeSubMenu === 2 ? "active" : ""
                          }`}
                      >
                        <Link
                          className="sub-menu-lists"
                          onClick={() => toggleSubMenu(2)}
                        >
                          Lists shared with me
                          <img
                            src="/image/cand-vactor.png"
                            className=""
                            alt=""
                          />
                        </Link>
                        <ul
                          className={`second-menu-dropdown second-menu-dropdown-border ${activeSubMenu === 2 ? "d-block" : ""
                            }`}
                        >
                          {listSharedWithMe?.map((j, i) => (
                            <li
                              className="sub-menu-dropdpwn"
                              onClick={() =>
                                onCandidateListHandler(
                                  j?.id,
                                  j?.name,
                                  EMP_CAND_LIST.SHARE_LIST
                                )
                              }
                            >
                              <Link className="sub-menu-lists">
                                {j?.name}
                                <img
                                  src="/image/table-list.png"
                                  className="page-copy-img"
                                  alt=""
                                />
                              </Link>
                            </li>
                          ))}
                        </ul>
                      </li>
                    )}
                  </ul>
                </li>
              </ul>
            </div>
          </OutsideClickHandler>

          {isActive ? (
            <div className="edit-post-container mb-md-0 mb-2 pb-md-4">
              <button onClick={() => editJobClickHanlder(selectedJobId)}>
                Edit{" "}
                <img
                  src="/image/edit-dash-m.png"
                  alt=""
                  className="edit-post-container-icon"
                />
              </button>
            </div>
          ) : (
            <></>
          )}
        </div>

        <div className="job-time-wishlist text-right">
          <div className="job-eployee-table-col">
            <p className="job d-md-block d-none">
              Job will disappear in {getDaysUntilExpiration(expireDate)} Days
            </p>
            <p className="day pt-md-2 pt-0">
              {" "}
              {date
                ? getDays(date) > 1
                  ? "Posted " + getDays(date) + " days ago"
                  : "Posted " + getDays(date) > 0
                    ? "Posted " + getDays(date) + " day ago"
                    : "Posted today"
                : ""}
            </p>
          </div>
        </div>
      </div>

    </div>
  );
};

export default JobListingRight;
