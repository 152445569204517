import { useState, useEffect } from "react";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { Button } from "react-bootstrap";
import { toast } from "react-toastify";
import { DateRangePicker } from "react-date-range";
import { addMonths, addDays, format } from "date-fns";
import { enUS } from "date-fns/locale";
import API from "../../../../api";
import ActionModal from "../../../../components/Common/ActionModal/ActionModal";
import { FormDropdown } from "../../../../components/Admin/Promotion/CreatePromotion/FormDropdown";
import { DemoPositionPage } from "../CreatePromotion/DemoPositionPage";
import {
  createPromotionReqAsync,
  editPromotionReqAsync,
  getEditPromotionDataAsync,
  getPositionForPromotionDataAsync,
  getWeeksPromotionDataAsync,
  setSelectedEditProID,
  setSelectedPromotionType,
  setSelectedTimeFrame,
  setSelectedJob,
  setSelectedPage,
  setSelectedPromotionDate,
  getPromotionJobsAsync,
  getCreatePromotionPagesForAdsAsync,
} from "../../../../redux/slices/companyPromotionSlice";
import { setActiveSideBar } from "../../../../redux/slices/AdminDashboardSlice";
import { COUNTRY_ISO_CODE } from "../../../../constants";
import capitalizeFirstLetter from "../../../../utils/capitalizeFirstLetter";
import "./index.css";

const AdminCreatePromotionSlot = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {
    promotionFormMode,
    selectedEditProID,
    selectedPromotionType,
    selectedTimeFrame,
    selectedPromotionDate,
    selectedJob,
    selectedPage,
  } = useSelector((state) => state.companyPromotion);
  const { userCountry } = useSelector((state) => state.auth);
  const [loader, setLoader] = useState(false);
  const [spinner, setSpinner] = useState(false);

  const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] =
    useState(false);

  /*Dropdown Value*/
  const [promotionJobs, setPromotionJobs] = useState([]);
  const [promotionJobsLoading, setPromotionJobsLoading] = useState(false);
  const [promotionPages, setPromotionPages] = useState([]);
  const [promotionPagesLoading, setPromotionPagesLoading] = useState(false);
  const [promotionDate, setPromotionDate] = useState([]);
  const [promotionDateLoading, setPromotionDateLoading] = useState(false);

  const [avaSlotD, setAvaSlotD] = useState([]);

  /*Selected Value*/
  const [selectedAvaSlot, setSelectedAvaSlot] = useState([]);
  const [selectedAvaSlotLoading, setSelectedAvaSlotLoading] = useState(false);

  /*Position*/
  const [allPosition, setAllPosition] = useState([]);
  const [selectedAllPosition, setSelectedAllPosition] = useState(null);
  const [occupiedPosition, setOccupiedPosition] = useState([]);
  const [editOccupiedPosition, setEditOccupiedPosition] = useState([]);

  const [enableSidebar, setEnableSidebar] = useState(false);

  /* Date Picker */
  const [selectionRange, setSelectionRange] = useState({
    startDate: selectedPromotionDate.start_date_D
      ? new Date(selectedPromotionDate.start_date_D)
      : new Date(),
    endDate: selectedPromotionDate.end_date_D
      ? new Date(selectedPromotionDate.end_date_D)
      : addDays(new Date(), selectedPromotionType?.id === 1 ? 29 : 6),
    key: "selection",
  });

  const formik = useFormik({
    initialValues: {
      promotion: "",
      promotionId: null,
      timeFrame: "",
      timeFrameId: null,
      promotionDate: "",
      promotionDateId: null,
      job: "",
      jobId: null,
      page: "",
      pageId: null,
    },
    /*validationscheme*/
    validationSchema: Yup.object({
      promotionDate: Yup.string(),
      job: Yup.string().when("promotion", {
        is: (value) => value?.toLowerCase() === "promote my job",
        then: () => Yup.string().required("Please select a job to promote"),
        otherwise: () => Yup.string(),
      }),
      page: Yup.string().when("promotion", {
        is: (value) => value?.toLowerCase() === "promote my job",
        then: () => Yup.string().required("Please select a page to promote"),
        otherwise: () => Yup.string(),
      }),
    }),
    onSubmit: async (data, { resetForm }) => {
      if (promotionFormMode === "add") {
        createPromotionReqClick();
      } else {
        editPromotionReqClick();
      }
    },
  });

  const navHandler = () => {
    if (promotionFormMode === "add") navigate(-1);
    else navigate("/admin-promotion-req");
  };

  const dateRangeSelectHandler = ({ selection }) => {
    const { startDate } = selection;
    const endDateCounter = selectedPromotionType?.id === 1 ? 29 : 6;
    const calculatedEndDate = addDays(startDate, endDateCounter);
    const formattedStartDate = format(startDate, "yyyy-MM-dd");
    const formattedEndDate = format(calculatedEndDate, "yyyy-MM-dd");

    setSelectionRange({
      startDate,
      endDate: calculatedEndDate,
      key: "selection",
    });

    dispatch(
      setSelectedPromotionDate({
        start_date_D: startDate,
        end_date_D: calculatedEndDate,
        start_date: formattedStartDate,
        end_date: formattedEndDate,
      })
    );

    getPromotionJobs({
      start_date: formattedStartDate,
      end_date: formattedEndDate,
    });  
      setSelectedAllPosition(null);  
  };

  const hasNoJobsForSelectedPromotionDate = promotionJobs.length === 0;

  const onJobSelect = async (id, name, item) => {
    const {jobId, job} = formik.values;

    if (!hasNoJobsForSelectedPromotionDate) {
      await formik.setFieldValue("jobId", id);
      await formik.setFieldValue("job", item?.full_title);
      dispatch(setSelectedJob({ id: id, name: item?.full_title }));
    }
    if(jobId !== id){
      setSelectedAllPosition(null);
    }
  };

  const onJobPageSelection = async (id, name, item) => {
    const {pageId, page} = formik.values;
    await formik.setFieldValue("page", name);
    await formik.setFieldValue("pageId", id);
    dispatch(
      setSelectedPage({ id: id, name: name, section: item?.sections[0] })
    ); 
    if(pageId !== id){
      setSelectedAllPosition(null);
    }
  };

  const getPromotionJobs = async ({ start_date, end_date }) => {
    setPromotionJobsLoading(true);
    dispatch(getPromotionJobsAsync({ start_date, end_date }))
      .then((response) => {
        const res = response?.payload;
        if (res?.success === true) {
          setPromotionJobs(res?.data);
        }
        setPromotionJobsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setPromotionJobsLoading(false);
      });
  };

  const onAvaSlotClick = (item) => {
    setSelectedAvaSlot(item);
  };

  const onCompanyCardAddClick = async (curentPosition) => {
    setSelectedAllPosition(curentPosition);
  };

  const onCompanyCardDeleteClick = async () => {
    setSelectedAllPosition(null);
  };

  const getPromotionPagesData = async (time_frame_id) => {
    setPromotionPagesLoading(true);
    dispatch(getCreatePromotionPagesForAdsAsync())
      .then((response) => {
        const res = response?.payload;
        if (res?.success === true) {
          setPromotionPages(res?.data);
        }
        setPromotionPagesLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setPromotionPagesLoading(false);
      });
    getPromotionDate(time_frame_id);
  };

  const getPromotionDate = async (time_frame_id) => {
    if (time_frame_id !== undefined) {
      setPromotionDateLoading(true);
      dispatch(getWeeksPromotionDataAsync({ timeFrame: time_frame_id }))
        .then((response) => {
          const res = response?.payload;
          if (res?.success === true) {
            setPromotionDate(
              res?.data.map((date, index) => ({
                id: index,
                name: `${date?.week_start_day} - ${date?.week_end_day}`,
                ...date,
              }))
            );
          }
          setPromotionDateLoading(false);
        })
        .catch((error) => {
          console.log(error);
          setPromotionDateLoading(false);
        });
    }
  };

  const createPromotionReqClick = async () => {
    setLoader(true);
    const body = {
      promotionTypeId: selectedPromotionType?.id,
      timeFrameId: selectedTimeFrame?.id,
      startDate: selectedPromotionDate?.start_date,
      endDate: selectedPromotionDate?.end_date,
      positionNumber: selectedAllPosition?.id,
      pageId: selectedPromotionType?.id == 2 ? selectedPage?.id : 1,
      sectionId: selectedAllPosition?.section_id,
    };
    if (selectedPromotionType?.id == 2) {
      body.jobId = formik?.values?.jobId;
      body.positionNumber = selectedAllPosition?.position;
    }

    dispatch(createPromotionReqAsync(body))
      .then((response) => {
        const res = response?.payload;
        if (res?.success === true) {
          if (res?.data?.iso_country_code === "CA") {
            invokePromotion(res?.data);
          }
          dispatch(
            setActiveSideBar({
              id: "32",
              name: "All promotions",
              value: "All promotions",
            })
          );
          navigate("/admin-all-promotion");
        } else {
          toast.warning(res?.message, {
            autoClose: 2000,
            closeOnClick: false,
          });
        }
        setLoader(false);
      })
      .catch((error) => {
        setLoader(false);
        console.log(error);
      });
  };

  const invokePromotion = async (data) => {
    const formData = {
      user_id: data.requested_by,
      promotion_type: parseInt(data.type_id),
      promotion_id: data.id,
      unit_amount: data.price,
      country: data.iso_country_code,
    };
    await API.post(`/job-board/purchase-promotion`, formData)
      .then((response) => {
        if (response) {
          const url = response.data.checkout_url;
          const newWindow = window.open(url, "_self", "noopener,noreferrer");
          if (newWindow) {
            newWindow.opener = null; // Prevent new tab from having access to current tab
            newWindow.location.href = url; // Navigate the new tab to the specified URL
          } else {
            // Fallback if opening in new tab fails
            navigate(url); // Navigate within the current tab if new tab failed to open
          }
        }
      })
      .catch((error) => {
        if (error) {
          console.log(error);
        }
        setLoader(false);
      });
  };

  const editPromotionReqClick = async () => {
    setLoader(true);
    const body = {
      selectedEditProID: selectedEditProID,
      promotionTypeId: selectedPromotionType?.id,
      timeFrameId: selectedTimeFrame?.id,
      startDate: selectedPromotionDate?.start_date,
      endDate: selectedPromotionDate?.end_date,
      positionNumber: selectedAllPosition?.id,
      pageId: selectedPromotionType?.id == 2 ? selectedPage?.id : 1,
      sectionId: selectedAllPosition?.section_id,
    };
    if (selectedPromotionType?.id == 2) {
      body.jobId = formik?.values?.jobId;
      body.positionNumber = selectedAllPosition?.position;
    }
    dispatch(editPromotionReqAsync(body))
      .then((response) => {
        const res = response?.payload;
        if (res?.success === true) {
          toast.success(res?.message, {
            autoClose: 2000,
            closeOnClick: false,
          });
          dispatch(setSelectedEditProID(null));
          dispatch(
            setActiveSideBar({
              id: "34",
              name: "Promotions requests",
              value: "Promotions requests",
            })
          );
          navigate("/admin-promotion-req");
        } else {
          toast.warning(res?.message, {
            autoClose: 2000,
            closeOnClick: false,
          });
        }
        setLoader(false);
      })
      .catch((error) => {
        setLoader(false);
        console.log(error);
      });
  };

  const getPositionForPromotionData = async () => {
    if (
      selectedPromotionDate?.start_date !== undefined &&
      selectedPromotionDate?.end_date !== undefined
    ) {
      const body = {
        promotionTypeId: selectedPromotionType?.id,
        page_id: selectedPromotionType?.id == 2 ? selectedPage?.id : 1,
        startDate: selectedPromotionDate?.start_date,
        endDate: selectedPromotionDate?.end_date,
      };
      if (promotionFormMode === "edit") {
        body.promotionId = selectedEditProID;
      }
      dispatch(getPositionForPromotionDataAsync(body))
        .then((response) => {
          const res = response?.payload;
          if (res?.success === true) {
            setAllPosition(res?.data?.all_positions);
            setOccupiedPosition(res?.data?.occupied_positions);
            setEditOccupiedPosition(res?.data?.edit_occupied_positions);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const getWeeksPromotionData = async (timeFrame) => {
    if (timeFrame !== undefined) {
      setSelectedAvaSlotLoading(true);
      dispatch(getWeeksPromotionDataAsync({ timeFrame: timeFrame }))
        .then((response) => {
          const res = response?.payload;
          if (res?.success === true) {
            setAvaSlotD(res?.data);
            if (promotionFormMode === "add") setSelectedAvaSlot(res?.data[0]);
          }
          setSelectedAvaSlotLoading(false);
        })
        .catch((error) => {
          console.log(error);
          setSelectedAvaSlotLoading(false);
        });
    }
  };

  const getEditProData = async () => {
    if (selectedEditProID !== null) {
      setSpinner(true);
      dispatch(
        getEditPromotionDataAsync({ selectedEditProID: selectedEditProID })
      )
        .then(async (response) => {
          setSpinner(false);
          const res = response?.payload;
          if (res?.success === true) {
            const newRes = res?.data;
            const promotionType = res?.data?.promotion?.type;
            const promotionTimeFrame = res?.data?.promotion?.time_frame;
            const promotion = res?.data?.promotion;
            const page = res?.data?.promotion?.page;
            const job = res?.data?.promotion?.promotable;
            const week = res?.data?.promotion?.week;

            await formik.setFieldValue("promotionId", promotionType?.id);
            await formik.setFieldValue("promotion", promotionType?.name);
            await formik.setFieldValue("timeFrameId", promotionTimeFrame?.id);
            await formik.setFieldValue("timeFrame", promotionTimeFrame?.name);
            await formik.setFieldValue(
              "promotionDate",
              week?.week_start_day + " - " + week?.week_end_day
            );
            setSelectedAvaSlot(week);
            setSelectedAllPosition(promotion?.selectedPosition);
            getWeeksPromotionData(promotionTimeFrame?.id);

            // Set the date range using the API response
            if (week?.start_date && week?.end_date) {
              setSelectionRange({
                startDate: new Date(week?.start_date),
                endDate: new Date(week?.end_date),
                key: "selection",
              });
            }

            if (promotionType?.id === 2) {
              await formik.setFieldValue("page", page?.name);
              await formik.setFieldValue("pageId", page?.id);

              await formik.setFieldValue("jobId", job?.id);
              await formik.setFieldValue("job", job?.name);

              dispatch(setSelectedPage({ id: page?.id, name: page?.name }));
              dispatch(setSelectedJob({ id: job?.id, name: job?.name }));
            }

            dispatch(
              setSelectedPromotionType({
                id: promotionType?.id,
                name: promotionType?.name,
              })
            );
            dispatch(
              setSelectedTimeFrame({
                id: promotionTimeFrame?.id,
                name: promotionTimeFrame?.name,
              })
            );
            if (week?.start_date && week?.end_date) {
            dispatch(
              setSelectedPromotionDate({
                start_date_D: new Date(week?.start_date),
                end_date_D: new Date(week?.end_date),
                start_date: week?.start_date,
                end_date: week?.end_date,
              })
            );
          }
        }
        })
        .catch((error) => {
          setSpinner(false);
          console.log(error);
        });
    }
  };

  const isFormFieldValid = (name) =>
    !!(formik.touched[name] && formik.errors[name]);
  const getFormErrorMessage = (name) => {
    return (
      isFormFieldValid(name) && (
        <p className="error-msg">{formik.errors[name]}</p>
      )
    );
  };

  useEffect(() => {
    if (promotionFormMode === "edit") getEditProData();
  }, [selectedEditProID]);

  useEffect(() => {
    getPositionForPromotionData();
  }, [
    selectedPromotionType,
    selectedTimeFrame,
    selectedAvaSlot,
    selectedPromotionDate,
    selectedPage,
    formik?.values?.jobId,
  ]);

  useEffect(() => {
    async function getSelectedProValue() {
      await formik.setFieldValue("promotionId", selectedPromotionType?.id);
      await formik.setFieldValue("promotion", selectedPromotionType?.name);
      await formik.setFieldValue("timeFrameId", selectedTimeFrame?.id);
      await formik.setFieldValue("timeFrame", selectedTimeFrame?.name);
      await formik.setFieldValue("pageId", selectedPage?.id);
      await formik.setFieldValue("page", selectedPage?.name);
      await formik.setFieldValue("promotionDateId", selectedPromotionDate?.id);
      await formik.setFieldValue("promotionDate", selectedPromotionDate?.name);
      await formik.setFieldValue("jobId", selectedJob?.id);
      await formik.setFieldValue("job", selectedJob?.name);
    }

    if (promotionFormMode === "add" || selectedPromotionType?.id == 2) {
      getSelectedProValue();
      //getWeeksPromotionData(selectedTimeFrame?.id);
    }
    //getPromotionListingData();
    getPromotionPagesData();
    //getPromotionDate(selectedTimeFrame?.id);
    getPromotionJobs({
      start_date: selectedPromotionDate?.start_date,
      end_date: selectedPromotionDate?.end_date,
    });
  }, []);

  return (
    <>
      <div className="department-role-space bg-clr-admin">
        <div className="form-heading-prom">
          <img
            src="/image/form-back-arrow.png"
            alt=""
            onClick={() => navHandler()}
          />
          <h2>
            {promotionFormMode === "add"
              ? `Create promotion`
              : `Edit promotion`}
          </h2>
        </div>
        {spinner ? (
          <div className="loader-div">
            <span
              className="spinner-border spinner-border"
              role="status"
              aria-hidden="true"
            />
          </div>
        ) : (
          <div className="pormotion-form-box">
            <form onSubmit={formik.handleSubmit}>
              <div className="admin-from-step-pro">
                <div
                  className={` ${
                    selectedPromotionType?.id === 2 && "vertial-line pr-20"
                  }`}
                >
                  <label>Select date *</label>
                  <div>
                    <DateRangePicker
                      ranges={[selectionRange]}
                      onChange={dateRangeSelectHandler}
                      months={2}
                      direction="horizontal"
                      editableDateInputs={false}
                      staticRanges={[]}
                      inputRanges={[]}
                      locale={enUS}
                      minDate={new Date()}
                      maxDate={addMonths(new Date(), 2)}
                      preventSnapRefocus={true}
                    />
                  </div>
                </div>

                <div className="dd-container-pro">
                  {formik?.values?.promotionId === 2 &&
                    formik?.values?.timeFrameId && (
                      <>
                        <FormDropdown
                          label="Select job to promote"
                          placeholder="Job title"
                          required
                          value={formik?.values?.job}
                          loading={promotionJobsLoading}
                          isInvalid={isFormFieldValid("job")}
                          error={getFormErrorMessage("job")}
                          options={
                            hasNoJobsForSelectedPromotionDate
                              ? [{ id: 0, name: "No jobs for selected date" }]
                              : promotionJobs
                          }
                          onSelect={onJobSelect}
                          formik={formik}
                        />
                        <br />
                        <FormDropdown
                          label="Select location"
                          placeholder="Location"
                          required
                          value={formik?.values?.page}
                          loading={promotionPagesLoading}
                          isInvalid={isFormFieldValid("page")}
                          error={getFormErrorMessage("page")}
                          options={promotionPages.map((page) => ({
                            ...page,
                            name:
                              page?.name === "Find Job Page"
                                ? "Search Page"
                                : page?.name,
                          }))}
                          onSelect={onJobPageSelection}
                          formik={formik}
                        />
                      </>
                    )}
                </div>
              </div>
              {selectedAllPosition !== null && (
                <div className="slots-form-boxes-list">
                  <div className="left-right-boxes-admin">
                    <div className="left-boxes-cards">
                      <div className="left-box-card-content">
                        <p className="job-postion-h">Selected promotion</p>
                        <div className="second-row-l-c">
                          <p className="slot-left-text">Job Ads promotion</p>
                          <p className="slot-left-text-right">Price</p>
                        </div>
                        <div className="third-row-l-c">
                          <div className="three-img-text">
                            <img
                              src="/image/chat-cut-black.png"
                              alt=""
                              className="icon-cursor"
                              onClick={() =>
                                setShowDeleteConfirmationModal(true)
                              }
                            />
                            <img
                              src={
                                formik?.values?.promotionId === 2
                                  ? "/image/slot-pormotion.png"
                                  : "/image/slot-pormotion-company.png"
                              }
                              alt=""
                            />
                            <p className="slot-left-text-job">
                              {formik?.values?.promotionId === 2
                                ? "Job Ads promotion "
                                : "Top company promotion "}

                              {" - "}
                              {selectedAllPosition?.page_id === 2
                                ? `${capitalizeFirstLetter(
                                    selectedAllPosition?.section?.name
                                  )}: position ${selectedAllPosition?.position}`
                                : `Position ${selectedAllPosition?.position}`}
                            </p>
                          </div>
                          <p className="slot-left-text-salary">
                            {selectedAllPosition?.country?.currency?.title}
                            {selectedAllPosition?.price}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="right-box-lists">
                      <div className="right-box-card">
                        {userCountry?.name !== COUNTRY_ISO_CODE.CA && (
                          <>
                            <p className="slot-heading-r">Total payable</p>
                            <div className="two-select-list">
                              <div className="list-one-select">
                                <p className="postion-list-c">Sub-total</p>
                                <p className="list-date-c">
                                  {
                                    selectedAllPosition?.country?.currency
                                      ?.title
                                  }{" "}
                                  {selectedAllPosition?.price}
                                </p>
                              </div>
                              <div className="list-one-select">
                                <p className="postion-list-c">
                                  Tax (
                                  {selectedAllPosition?.tax_rate_percentage}%)
                                </p>
                                <p className="list-date-c">
                                  {
                                    selectedAllPosition?.country?.currency
                                      ?.title
                                  }{" "}
                                  {selectedAllPosition?.tax_amount}
                                </p>
                              </div>
                              <div className="list-one-total">
                                <p className="total-list-c">Total</p>
                                <p className="total-date-c">
                                  {
                                    selectedAllPosition?.country?.currency
                                      ?.title
                                  }{" "}
                                  {selectedAllPosition?.total_amount}
                                </p>
                              </div>
                            </div>
                          </>
                        )}
                        <button
                          type="submit"
                          className="boooking-req"
                          disabled={loader}
                        >
                          {loader ? (
                            <span
                              className="spinner-border spinner-border-sm"
                              role="status"
                              aria-hidden="true"
                            />
                          ) : promotionFormMode === "add" ? (
                            `Request booking`
                          ) : (
                            `Edit & Save`
                          )}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              <div className="mb-2 p-3 pt-2">
                <hr />
              </div>
              <div className="demo-page-container">
                <div className="availabel-slot-sec-create">
                  <DemoPositionPage
                    allPosition={allPosition}
                    selectedAllPosition={selectedAllPosition}
                    occupiedPosition={
                      promotionFormMode === "add"
                        ? occupiedPosition
                        : editOccupiedPosition
                    }
                    onCompanyCardAddClick={onCompanyCardAddClick}
                    isJobAd={formik.values.promotionId === 2}
                    isSearchPage={
                      formik.values.pageId === 2 &&
                      formik.values.promotionId === 2
                    }
                    enableSearchSidebar={enableSidebar}
                    sections={
                      promotionPages?.find((page) => page?.id === 2)?.sections
                    }
                    dates={selectedAvaSlot}
                    selectedJob={formik.values.jobId}
                  />
                </div>
              </div>
            </form>
          </div>
        )}
      </div>
      {showDeleteConfirmationModal && (
        <ActionModal
          heading="Double-Check"
          closeModal={() => setShowDeleteConfirmationModal(false)}
          modalArt="/image/warning-wishlist.png"
          content="Are you sure you want to delete this booking?"
        >
          <div className="d-flex justify-content-center w-100">
            <Button
              onClick={() => setShowDeleteConfirmationModal(false)}
              size="lg"
              variant="dark"
              className="modal-btn me-3"
            >
              No
            </Button>
            <Button
              onClick={() => {
                onCompanyCardDeleteClick();
                setShowDeleteConfirmationModal(false);
              }}
              size="lg"
              variant="outline-dark"
              className="modal-btn"
            >
              Yes
            </Button>
          </div>
        </ActionModal>
      )}
    </>
  );
};

export default AdminCreatePromotionSlot;
