import ReactHtmlParser from "react-html-parser";
import "./CanResumeTemp.css";

const CanResumeTemp = ({
  originalData,
  contactDetails,
  experience,
  projects,
  education,
  certifications,
  keySkills,
}) => {

  const handlePreviewResume = (resumeURL) => {
    if(resumeURL !== null){
      const pdfUrl = resumeURL;
      window.open(pdfUrl, "_blank");
    }
  };

  return (
    <>
      <div className="resume-verivication-res">
        <div className="resume-start-verivication">
          <div className="all-content-resume-verification">
            <div className="name-res-left">{`${
              contactDetails?.first_name !== null
                ? contactDetails?.first_name
                : ""
            } ${
              contactDetails?.last_name !== null
                ? contactDetails?.last_name
                : ""
            }`}</div>

            {contactDetails?.address !== null &&
              contactDetails?.address !== "" && (
                <div className="location-res-left">
                  <p>{contactDetails?.address}</p>
                </div>
              )}
            <div className="resume-can-info-left">
              {contactDetails?.email !== "" && (
                <div className="box-one-res-l">
                  <span>Email:</span>
                  <a href="#">{contactDetails?.email}</a>
                </div>
              )}
              {contactDetails?.phone_number !== "" && (
                <div className="box-two-res-l">
                  <span>Phone:</span>
                  <a href="#">{contactDetails?.phone_number}</a>
                </div>
              )}
            </div>
            <div className="resume-content-verification">
              {contactDetails?.candidate_summary !== "" && (
                <>
                  <div className="first-heading-res-left">Summary</div>
                  <p className="res-sumry-left">
                    {contactDetails?.candidate_summary}
                  </p>
                </>
              )}

              {experience?.length > 0 && (
                <>
                  <div className="first-heading-res-left">
                    Professional Experience
                  </div>
                  {experience?.map((prod, index) => (
                    <>
                      <div className="time-comp-res-left">
                        <div className="sub-heading-res-left">
                          {prod?.company_name !== ""
                            ? prod?.company_name
                            : "Employer"}
                            {prod?.city !== ""
                            ? `, ${prod?.city}`
                            : ""}
                             {prod?.country !== ""
                            ? `, ${prod?.country}`
                            : ""}
                        </div>
                        <div className="sub-heading-res-right">
                          {prod?.start_date?.month === "" &&
                          prod?.start_date?.year === ""
                            ? "Start Date"
                            : `${prod?.start_date?.month?.substring(0, 3)} ${
                                prod?.start_date?.year
                              }`}{" "}
                          {prod?.is_present
                            ? "- Present"
                            : prod?.end_date?.month === "" &&
                              prod?.end_date?.year === ""
                            ? "- End Date"
                            : `- ${prod?.end_date?.month?.substring(0, 3)} ${
                                prod?.end_date?.year
                              }`}
                        </div>
                      </div>
                      <p className="res-postion-left">
                        Position:{" "}
                        <span>
                          {prod?.job_title !== ""
                            ? prod?.job_title
                            : "Job Title"}                        
                        </span>{" "}
                      </p>
                      <div className="cus-resume-job-list-left">
                        {prod?.roles_n_responsibilities !== "" &&
                          ReactHtmlParser(prod?.roles_n_responsibilities)}
                      </div>
                    </>
                  ))}
                </>
              )}

              {projects?.length > 0 && (
                <>
                  <div className="project-resu">
                    <div className="first-heading-res-left">Projects</div>
                    {projects?.map((prod, index) => (
                      <>
                        <div className="time-comp-res-left">
                          <div className="sub-heading-res-left">
                            {prod?.name !== "" ? prod?.name : "Project Name"}
                          </div>
                        </div>
                        <div className="project-comp-res-left">
                          {prod?.url !== "" && (
                            <p className="res-postion-left">
                              Project URL:{" "}
                              <a
                                target="_blank"
                                href={prod?.url !== "" ? prod?.url : "#"}
                                className="project-link-res"
                              >
                                {prod?.url !== "" ? prod?.url : ""}
                              </a>{" "}
                            </p>
                          )}
                        </div>
                        {prod?.description && prod?.description !== "" && (
                          <ul className="resume-job-list-left">
                            <li>{prod?.description} </li>
                          </ul>
                        )}
                      </>
                    ))}
                  </div>
                </>
              )}

              {education?.length > 0 && (
                <>
                  <div className="education-resu">
                    <div className="first-heading-res-left">Education</div>
                    {education.map((prod, index) => (
                      <>
                        <div className="time-comp-res-left">
                          <div className="sub-heading-res-left">
                            {prod?.institute !== ""
                              ? prod?.institute
                              : "University/Institute Name"}
                          </div>
                          <div className="sub-heading-res-right">
                            {prod?.start_date?.month === "" &&
                            prod?.start_date?.year === ""
                              ? "Start Date"
                              : `${prod?.start_date?.month?.substring(0, 3)} ${
                                  prod?.start_date?.year
                                }`}{" "}
                            {prod?.is_present
                              ? "- Present"
                              : prod?.end_date?.month === "" &&
                                prod?.end_date?.year === ""
                              ? "- End Date"
                              : `- ${prod?.end_date?.month?.substring(0, 3)} ${
                                  prod?.end_date?.year
                                }`}
                          </div>
                        </div>
                        <p className="edu-resume-left">
                          {prod?.degree !== "" ? prod?.degree : "Degree"}
                        </p>
                      </>
                    ))}
                  </div>
                </>
              )}

              {certifications?.length > 0 && (
                <>
                  <div className="certificat-resume">
                    <div className="first-heading-res-left certificat-resume-heading">
                      Certifications
                    </div>
                    {certifications.map((prod, index) => (
                      <div className="certification-res-jour">
                        <ul className="certificate-res-list-left">
                          <li>
                            {" "}
                            {prod?.name !== ""
                              ? prod?.name
                              : "Certification Name"}
                          </li>
                        </ul>
                        <div className="sub-heading-res-right">
                          {prod?.start_date?.month === "" &&
                          prod?.start_date?.year === ""
                            ? "Start Date"
                            : `${prod?.start_date?.month?.substring(0, 3)} ${
                                prod?.start_date?.year
                              }`}{" "}
                          {prod?.end_date?.month === "" &&
                          prod?.end_date?.year === ""
                            ? "- End Date"
                            : `- ${prod?.end_date?.month?.substring(0, 3)} ${
                                prod?.end_date?.year
                              }`}
                        </div>
                      </div>
                    ))}
                  </div>
                </>
              )}

              {keySkills?.length > 0 && (
                <>
                  <div className="first-heading-res-left">Skills</div>
                  <ul className="resume-job-list-skill">
                    <li>
                      {keySkills.map(
                        (prod, index) => prod?.name !== "" && `${prod?.name}, `
                      )}
                    </li>
                  </ul>
                </>
              )}

              <div className="first-heading-res-left">References</div>
              <ul className="resume-job-list-skill">
                <li>Available on request</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="mbl-resume-all">
        <div className="resume-name-mbl">
          <a href="#" target="_blank">
            {originalData?.fullName !== "" && originalData?.fullName}{" "}   
          </a>
          <img
            onClick={() => handlePreviewResume(originalData?.originalResumeURL)}
            src="/image/mbl-cv-icon.png"
            alt=""
            className="preview-icon-job"
          />
        </div>
      </div>
    </>
  );
};

export default CanResumeTemp;
