import React from 'react';
import './Card.css'; // Import the CSS file for styling

const Card = ({videoId,title,thumbnails,views,uploadTime , onClick}) => {
  return (
    <div className="card">
      <div className="video-card"
       key={videoId} 
        onClick={onClick}
          >
            <img
              src={thumbnails}
              alt={title}
              className="thumbnail"
            />
            <div className="video-info">
              <p className='video-title-sub m-0'>Video - Job interview</p>
              <h2 className="video-title mt-2">{title}</h2>
              <p className='video-title-sub m-0'>
                {views} {views === 1 ? "view" : "views"} - {uploadTime}
              </p>
            </div>
          </div>

        {/* <div className="card-footer">
          <span className="views">443K Views</span>
          <span className="time">22 hours ago</span>
        </div> */}
      </div>
  );
};

export default Card;